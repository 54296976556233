$black-color: #0e0000;
$red-color: #8b0000;
$border-radius: 4px;
$font-weight-m: 600;
$font-size: 14px;
$border-none: none;
$display-flex: flex;
$align-items-center: center;
$button-color: #530000;

body {
    overflow-x: hidden;
}

.content {
    .breadcrumb {
        li {
            letter-spacing: 0;
            color: $black-color;
            opacity: 1;
            margin: 10px 10px 5px 0;
            font-size: 20px;
            font-weight: $font-weight-m;
        }
    }

    .top-dropdown {
        margin: 10px 0;

        select {
            border-radius: 4px;
            letter-spacing: 0.13px;
            color: #425b50;
            opacity: 1;
            font-size: 12px;
            font-weight: 500;
            border: $border-none;
            padding: 0 8px;
            font-family: "Sarabun", sans-serif;
            background-color: #fff !important;
            height: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 16px;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &.cal-select {
            select {
                width: 145px !important;
            }
        }

        .react-datepicker-wrapper {
            input {
                border: none;
                border-radius: 4px;
                width: 100%;
                font-size: 10.5px;
                font-weight: 500;
                padding-left: 20px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &.date-to {
                    padding: 5.5px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &.date-from {
                    padding-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            svg {
                position: absolute;
                left: 5px;
                top: 9px;
                font-size: 13px;
            }
        }

        .react-datepicker__day--keyboard-selected:hover {
            color: #fff;
            font-weight: normal !important;
        }

        &.multi-calendar {
            .react-datepicker-wrapper {
                width: 100%;

                input {
                    height: 30px;
                    background-color: #fff;
                }
            }

            .react-datepicker-popper {
                z-index: 9999;
            }
        }

        .connected-span {
            background-color: #fff;
            padding: 2.5px;
        }

        .show-calendar {
            .react-datepicker-wrapper {
                position: absolute;
                z-index: 99;
                width: 128px;

                input {
                    font-size: 11px;
                    padding-left: 16px;
                }
            }

            .react-datepicker {
                .react-datepicker__navigation {
                    width: 10px !important;
                    background-color: transparent !important;
                    padding-left: 0 !important;
                }
            }

            img {
                z-index: 999;
            }
        }

        a {
            text-decoration: none;
        }
    }

    .project-date-select {
        font-size: 10px !important;
        padding-right: 28px !important;
        padding-left: 5px !important;
        padding-bottom: 3px !important;
    }

    .filter,
    .export {
        button {
            letter-spacing: 0.13px;
            color: $black-color;
            opacity: 1;
            border-radius: $border-radius;
            background-color: #fff;
            border: $border-none;
            width: 100%;
            height: 30px;
            text-align: left;
            font-size: 12px;
            font-weight: 500;
            padding-left: 8px;
            padding-right: 0;
            white-space: nowrap;
            display: flex;
            align-items: center;

            img {
                margin-right: 2px;
                height: 12px;

                &.highlight {
                    height: 28px !important;
                }
            }

            &:active {
                background-color: #fff !important;
                border-color: #fff !important;
                color: $black-color !important;
            }
        }
    }

    .export {
        color: $red-color;
    }

    .search-select {
        padding: 0 !important;
        border: 0;
        border-radius: 4px;
        letter-spacing: 0.13px;
        color: #425b50;
        opacity: 1;
        font-size: 12px;
        font-weight: 500;
        padding: 0 8px;
        font-family: "Sarabun", sans-serif;
        background-color: #fff !important;
        height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
        width: 100%;

        .css-1jqq78o-placeholder {
            color: #425b50;
            opacity: 1;
            font-size: 12px;
            font-weight: 500;
        }

        .css-13cymwt-control {
            height: 30px;
            min-height: 30px;
            border: none;
        }

        .css-1u9des2-indicatorSeparator {
            display: none;
        }

        .css-1xc3v61-indicatorContainer,
        .css-15lsz6c-indicatorContainer {
            padding: 0;
        }

        .css-t3ipsp-control {
            border: 0 !important;
            box-shadow: none !important;
            height: 30px;
            min-height: 30px;

            &:hover {
                border-color: transparent !important;
            }
        }

        .css-1xc3v61-indicatorContainer {
            svg {
                fill: #425b50;
            }
        }

        .css-1nmdiq5-menu div,
        .css-1nmdiq5-menu,
        .css-1nmdiq5-menu a,
        .css-1nmdiq5-menu li,
        .select_menu-list {
            display: block !important;
        }
    }

    .Auto-complete-dropdown {
        width: 100%;
        z-index: 99;
        position: relative;

        div:nth-of-type(1) {
            width: 95%;
        }

        input {
            border: 0;
            outline: none;
            box-shadow: none;
            width: 100%;
            z-index: 99;
            border: 0;
            border-radius: 4px;
            letter-spacing: 0.13px;
            color: #425b50;
            opacity: 1;
            font-size: 12px;
            font-weight: 500;
            padding: 0 8px;
            font-family: "Sarabun", sans-serif;
            height: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            &::placeholder {
                color: #425b50;
            }

            &:disabled {
                background-color: transparent !important;
            }
        }

        .menu-dropdown {
            border-radius: 3px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
            background: rgba(255, 255, 255, 0.9);
            padding: 2px 0px;
            overflow-x: hidden;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            position: absolute !important;
            left: 0 !important;
            top: 30px !important;

            &:empty {
                display: block;
            }

            &.h-fixed {
                height: 200px;
                min-height: 200px;
                overflow-y: scroll;
                z-index: 999999999;
            }

            &.h-auto {
                height: auto;
                min-height: auto;
            }

            &.w-fixed {
                &.menu-right {
                    right: 0 !important;
                    left: unset !important;
                }
            }
        }

        .options {
            padding: 8px;
            border: 0;
            letter-spacing: 0.13px;
            color: #425b50;
            opacity: 1;
            font-size: 13px;
            font-weight: 500;
            font-family: "Sarabun", sans-serif;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            display: block;
        }

        .arrow {
            height: 0;
            position: absolute;
            right: 22px;
            top: 6px;
            width: 0;

            svg {
                fill: #425b50;
                height: 18px;
            }
        }

        &.form {
            div:has(input) {
                width: 100%;
            }

            input {
                background-color: #f5f5f5 !important;
                border-radius: 4px !important;
                height: 34px;
            }

            .arrow {
                svg {
                    fill: #686e78;
                    height: 18px;
                }
            }
        }
    }

    .input-group {
        .input-group-text {
            background: #fff;
            border: $border-none;
            padding-left: 10px;
            padding-right: 1px;
            border-radius: $border-radius;

            img {
                height: 14px;
            }
        }

        input {
            border: $border-none;
            background: #fff;
            letter-spacing: 0.13px;
            color: #000;
            opacity: 1;
            text-align: left;
            border-radius: $border-radius;
            border-top-right-radius: $border-radius !important;
            border-bottom-right-radius: $border-radius !important;
            border-left: 0 !important;
            font-size: 13px;
            padding-left: 8px;
            padding-right: 24px;
            height: 30px;

            &::placeholder {
                color: #5e3737;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .close-btn {
            position: absolute;
            right: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;

            i {
                font-size: 12px;
                color: #880000b8;
                font-weight: normal;
                cursor: pointer;
            }
        }

        .search-icon-btn {
            position: absolute;
            right: 30px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;

            i {
                font-size: 12px;
                color: #880000b8;
                font-weight: normal;
                cursor: pointer;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: black;
                    -webkit-transform: scale(1.3);
                    -moz-transform: scale(1.3);
                    -ms-transform: scale(1.3);
                    -o-transform: scale(1.3);
                    transform: scale(1.3);
                }
            }
        }

        .test {
            i {
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -ms-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: black;
                    -webkit-transform: scale(1.3);
                    -moz-transform: scale(1.3);
                    -ms-transform: scale(1.3);
                    -o-transform: scale(1.3);
                    transform: scale(1.3);
                }
            }
        }
    }

    .nav-pills {
        .nav-item {
            width: 50%;

            &:last-child {
                .nav-link {
                    margin-right: 0;
                }
            }

            .nav-link {
                letter-spacing: 0.13px;
                color: #425b50;
                opacity: 1;
                background-color: #fff;
                font-size: 15px;
                font-weight: $font-weight-m;
                border-radius: 4px;
                margin: 0 8px 0 0;
                padding: 8px 14px;
                display: $display-flex;
                align-items: $align-items-center;
                width: 88%;

                img {
                    margin-right: 5px;
                }
            }
        }

        &.top-tab {
            .nav-item {
                .nav-link {
                    background: #faf2f3;
                    border-radius: 0;
                    color: #4d4242;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: center;
                    margin-right: 0 !important;
                    width: 73px;

                    &.active {
                        background: $red-color;
                        color: #fff;
                    }
                }

                &:first-child {
                    .nav-link {

                        &.active,
                        &:not(.active) {
                            border-radius: 8px 0px 0px 8px !important;
                        }
                    }
                }

                &:last-child {
                    .nav-link {

                        &.active,
                        &:not(.active) {
                            border-radius: 0 8px 8px 0px !important;
                        }
                    }
                }
            }
        }
    }

    .card-list {
        // overflow: auto;

        .container {
            max-width: 80% !important;

            &.lg {
                max-width: 95% !important;
            }

            &.md {
                max-width: 60% !important;
            }

            &.sm {
                // max-width: 50% !important;
                width: clamp(20rem, 45rem, 100%) !important;
                max-width: clamp(20rem, 45rem, 100%) !important;
            }
        }

        .card {
            &:hover {
                background: #fdfafa;
            }

            position: static;

            &:nth-child(1) {
                .name-btn {
                    background: #074272;
                }
            }

            &:nth-child(2) {
                .name-btn {
                    background: #acac13;
                }
            }

            &:nth-child(3) {
                .name-btn {
                    background: #bc1653;
                }
            }

            &:nth-child(4) {
                .name-btn {
                    background: #000811;
                }
            }

            &:nth-child(5) {
                .name-btn {
                    background: #009665;
                }
            }

            &:nth-child(10n + 6) {
                .name-btn {
                    background: #8920a8;
                }
            }

            &:nth-child(10n + 7) {
                .name-btn {
                    background: #166fbc;
                }
            }

            &:nth-child(10n + 8) {
                .name-btn {
                    background: #166fbc;
                }
            }

            &:nth-child(10n + 9) {
                .name-btn {
                    background: #166fbc;
                }
            }

            &:nth-child(10n + 10) {
                .name-btn {
                    background: #009665;
                }
            }

            .rate-btn {
                background: #074272;
                border-radius: 8px;
                padding: 3px;
                font-size: 12px;
                color: #ffffff;
                font-weight: 500;
                margin-right: 10px;
                border: none;
                width: 30px;
                height: 30px;

                &.rate-1 {
                    background: #e43e3d;
                    border: 1px solid #e43e3d;
                    color: #fff;
                }

                &.rate-2 {
                    background: #ec654e;
                    border: 1px solid #ec654e;
                    color: #fff;
                }

                &.rate-3 {
                    background: #ef874c;
                    border: 1px solid #ef874c;
                    color: #fff;
                }

                &.rate-4 {
                    background: #f3a74c;
                    border: 1px solid #f3a74c;
                    color: #fff;
                }

                &.rate-5 {
                    background: #f8c43d;
                    border: 1px solid #f8c43d;
                    color: #fff;
                }

                &.rate-6 {
                    background: #e1c63b;
                    border: 1px solid #e1c63b;
                    color: #fff;
                }

                &.rate-7 {
                    background: #c1cc36;
                    border: 1px solid #c1cc36;
                    color: #fff;
                }

                &.rate-8 {
                    background: #9fcd35;
                    border: 1px solid #9fcd35;
                    color: #fff;
                }

                &.rate-9 {
                    background: #7fcd31;
                    border: 1px solid #7fcd31;
                    color: #fff;
                }

                &.rate-10 {
                    background: #5aaf2b;
                    border: 1px solid #5aaf2b;
                    color: #fff;
                }
            }
        }

        a {
            text-decoration: none;
        }

        .card {

            // display: table-caption;
            &.overdue {
                background: #fff8f8;
                border: 1px solid $red-color;
            }
        }

        .card-body {
            padding: 5px !important;

            .table {
                tbody {
                    td {
                        padding: 3px;

                        &:has(.action-btn),
                        &.action-td {
                            min-width: 20px;
                            max-width: 20px;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

    .export-dropdown {
        box-shadow: 0px 3px 4px #00000014;
        border-radius: $border-radius;
        border: $border-none;
        padding-bottom: 0;
        min-width: 130px;

        .dropdown-item {
            color: #5e3737;
            font-size: 12px;
            font-weight: 500;

            &:hover {
                background: $red-color;
                color: #fff;
            }
        }

        li {
            &:first-child {
                .dropdown-item {
                    &:hover {
                        border-radius: 8px 8px 0 0;
                    }
                }
            }

            &:last-child {
                .dropdown-item {
                    &:hover {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }
        }
    }

    .table {
        margin-bottom: 0;

        tbody {
            td {
                // width: 200px;
                min-width: 100px;
                max-width: 100px;
                border-bottom: none;
                background-color: transparent;

                a {
                    text-decoration: none;
                }

                .form-check-input {
                    margin-right: 5px;
                    margin-top: -1px;

                    &:checked {
                        background-color: $red-color;
                        border-color: $red-color;
                    }

                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border-color: $red-color;
                    }
                }

                a.btn {
                    &:focus {
                        outline: none;
                    }
                }

                svg {
                    color: $red-color;
                }
            }

            .tdata-first {
                display: $display-flex;

                button {
                    border-radius: 2px;
                    opacity: 1;
                    padding: 4px 10px;
                    border: $border-none;
                    font-size: 12px;
                    font-weight: $font-weight-m;
                    letter-spacing: 0.11px;
                    color: #57b14f;

                    &.moving {
                        background: #e1f7df;
                        color: #57b14f;
                    }

                    &.inactive {
                        background: #ededed;
                        color: #62676f;
                    }

                    &.idle {
                        background: #fcefda;
                        color: #fa9826;
                    }

                    &.stopped {
                        background: #fff2f2;
                        color: #f25555;
                    }

                    &.nearby {
                        background: #edf7ff;
                        border-radius: 2px;
                        margin: 0 4px;

                        i {
                            color: #2d88d6;
                            font-size: $font-size;
                        }
                    }
                }

                img {
                    height: 48.5px;
                }
            }

            .name-btn {
                background: #074272;
                border-radius: 8px;
                padding: 3px;
                font-size: 12px;
                color: #ffffff;
                font-weight: 500;
                margin-right: 10px;
                border: none;
                width: 30px;
                height: 30px;

                &:empty {
                    display: none;
                }
            }

            .name-title {
                color: #000811 !important;
                font-size: 14px !important;
                font-weight: 600 !important;
                margin-bottom: 0;

                &.show-view {
                    color: #2e2ef6;
                }
            }

            .v-title {
                margin: 0px 10px 0px 0px;

                h2 {
                    letter-spacing: 0px;
                    color: #120303;
                    opacity: 1;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: left;
                    margin-bottom: 2px;
                    line-height: 16px;
                    text-transform: math-auto;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;

                    &:empty::before {
                        content: "-";
                    }

                    &.show-view {
                        text-decoration: underline;
                        color: #2e2ef6;
                    }

                    a {
                        color: inherit;
                        text-decoration: none;
                        font-size: $font-size;
                        font-weight: $font-weight-m;
                    }

                    img {
                        height: 12px;
                        margin-right: 6px;
                    }

                    i {
                        font-size: 12px;
                        margin-right: 5px;

                        &.general {
                            color: #d6a92d;
                        }
                    }

                    &.nowrap {
                        white-space: nowrap;
                    }
                }

                p {
                    letter-spacing: 0px;
                    color: #5e3737b8;
                    opacity: 1;
                    font-size: 11px;
                    font-weight: 500;
                    margin-bottom: 0;
                    white-space: nowrap;
                }

                .chat-btn {
                    background: #fff5f5;
                    border-radius: $border-radius;
                    border: $border-none;
                    padding: 3px 5px;
                    margin: 0 6px;
                    display: $display-flex;
                    align-items: $align-items-center;
                    justify-content: center;

                    img {
                        height: 13px;
                        object-fit: contain;
                    }

                    .chat-badge-img {
                        height: 17px !important;
                        object-fit: contain;
                    }
                }

                .due-date-btn {
                    background: #fff5f5;
                    border-radius: $border-radius;
                    border: $border-none;
                    padding: 3px 5px;
                    margin: 0;
                    display: $display-flex;
                    align-items: $align-items-center;
                    justify-content: center;

                    img {
                        height: 15px;
                        object-fit: contain;
                    }
                }

                a {
                    letter-spacing: 0.13px;
                    color: #2d88d6;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 13px;
                    font-weight: 500;

                    img {
                        height: 22px;
                        margin-left: 4px;
                    }

                    &.hover {
                        letter-spacing: 0.13px;
                        color: #425b50;
                        opacity: 1;
                        font-size: $font-size;
                        font-weight: 500;
                        white-space: nowrap;

                        &:hover {
                            color: #2d88d6;
                        }

                        img {
                            height: 22px;
                        }
                    }
                }

                &.icons {
                    span {
                        background-color: #d9f7e9;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        margin-right: 5px;
                        display: inline-flex;
                        align-items: $align-items-center;
                        justify-content: center;

                        i {
                            color: #009e4f;
                            font-size: 12px;
                        }
                    }
                }
            }

            .priority-label {
                background: #fff5f5;
                border-radius: 4px;
                color: $red-color;
                font-size: 10px;
                padding: 6px;
                text-transform: capitalize;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;

                i {
                    font-size: 10px !important;
                    margin-right: 4px;
                }
            }

            .rfi-label {
                background: darkblue;
                border-radius: 4px;
                color: white;
                font-size: 10px;
                padding: 6px;
                text-transform: capitalize;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;

                i {
                    font-size: 10px !important;
                    margin-right: 4px;
                }
            }

            .prior-img {
                height: 28px;
            }

            .status-btn {
                border-radius: 2px;
                padding: 1px 5px;
                letter-spacing: 0px;
                text-align: center;
                font-size: 10px;
                font-weight: 500;
                margin: 0;
                border: $border-none;
                width: auto;
                text-transform: capitalize;
                background-color: #fee3e3;
                color: #910e0e;
                cursor: default;

                &:empty {
                    background-color: transparent !important;
                }

                &.created {
                    background: #f1e2fb;
                    color: #800080;
                }

                &.overdue {
                    background: #fcd8d8;
                    color: #e93d59;
                }

                &.error,
                &.errorreported {
                    background: #ffbaba;
                    color: #9f0e0e;
                }

                &.inreview,
                &.in-review {
                    background: #ffebd8;
                    color: #a14c00;
                }

                &.autoaccepted {
                    background: #d6e9f2;
                    color: #3891b9;
                }

                &.reopened,
                &.reopenrequested {
                    background: #ffe9fa;
                    color: #bf4ea5;
                }

                &.active {
                    background: #e1f7df;
                    color: #57b14f;
                }

                &.inactive {
                    background: #ffbaba;
                    color: #f44336;
                }

                &.accepted {
                    background: #e1f7df;
                    color: #7fc578;
                }

                &.underprogress {
                    background: #e2fbfb;
                    color: #66afaf;
                }

                &.autoaccepted {
                    background: #d6e9f2;
                    color: #2c8bb4;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                &.cancelled {
                    background: #ffbdbd;
                    color: #ff0e0e;
                }

                &.cancellationrequested,
                &.cancellation-requested {
                    background: #ffe5bd;
                    color: #ac8110;
                }
            }

            .status-icons {
                margin: 2px 0;

                img {
                    height: 24px;
                    margin-right: 5px;
                }
            }

            .locked-label {
                background: #fff5f5;
                padding: 3px 7px;
                // display: inline;
                margin-right: 5px;
                border-radius: 4px;
                font-size: 11px;
                color: $red-color;
                display: $display-flex;
                align-items: $align-items-center;
                font-weight: 500;

                svg {
                    width: 12px;
                }
            }

            .action-btn {
                background-color: transparent;
                border: $border-none;
                padding: 0;
                float: right;
                width: 100%;

                svg {
                    color: $red-color;
                }

                img {
                    height: 15px;
                }
            }

            .action-dropdown {
                box-shadow: 0px 3px 6px #002c1127;
                border-radius: 4px;
                border: $border-none;
                min-width: 120px;
                padding-bottom: 0;
                margin-left: -20px !important;

                .dropdown-item {
                    letter-spacing: 0px;
                    color: #747c8a;
                    font-size: $font-size;
                    font-weight: 400;
                    padding: 7px;
                    display: $display-flex;
                    align-items: $align-items-center;
                    background-size: 14px !important;
                    background-position: 10px !important;
                    padding-left: 35px;
                    cursor: pointer !important;

                    a {
                        color: inherit;
                        text-decoration: none;
                    }

                    &.active,
                    &:active,
                    &:hover {
                        background-color: $red-color !important;
                        color: #fff !important;
                    }

                    img {
                        height: 14px;
                    }

                    &.status {
                        background: url(../images/navbar/action-status.png) left center/contain no-repeat;

                        &:hover {
                            background: url(../images/navbar/action-status-h.png) left center/contain no-repeat;
                        }
                    }

                    &.lock {
                        padding-left: 11px !important;

                        &:before {
                            content: "\f023";
                            font-family: "FontAwesome";
                            padding-right: 13px;
                            font-size: 18px;
                            color: #747c8a;
                        }

                        &:hover {
                            &:before {
                                color: #fff;
                            }
                        }
                    }

                    &.unlock {
                        padding-left: 11px !important;

                        &:before {
                            content: "\f09c";
                            font-family: "FontAwesome";
                            padding-right: 7px;
                            font-size: 18px;
                            color: #747c8a;
                        }

                        &:hover {
                            &:before {
                                color: #fff;
                            }
                        }
                    }

                    &.edit {
                        background: url(../images/navbar/edit.png) left center/contain no-repeat;

                        &:hover {
                            background: url(../images/navbar/edit-h.png) left center/contain no-repeat;
                        }
                    }

                    &.delete {
                        background: url(../images/navbar/delete.png) left center/contain no-repeat;

                        &:hover {
                            background: url(../images/navbar/delete-h.png) left center/contain no-repeat;
                        }
                    }
                }
            }

            .btn-collapse {
                background-color: #fff;
                border: 0.5px solid #7c978a;
                border-radius: 2px;
                opacity: 1;
                padding: 8px 10px;
                display: $display-flex;
                align-items: $align-items-center;

                &:before {
                    content: "\f078";
                    font-family: "FontAwesome";
                    color: #7c978a;
                    font-size: $font-size;
                }

                &.collapsed {
                    &:before {
                        content: "\f077";
                    }
                }
            }
        }
    }

    .paginate {
        box-shadow: 0px -3px 9px #0000000f !important;
        border-radius: 0px 0px 10px 10px !important;
    }

    .pagination {
        .show-entries {
            label {
                display: $display-flex;
                align-items: $align-items-center;
                letter-spacing: 0.13px;
                color: #546f63;
                opacity: 1;
                font-size: $font-size;
                font-weight: 500;
                text-align: left;
            }

            select {
                border: 1px solid #022c1d;
                border-radius: 2px;
                width: 80px;
                height: 34px;
                margin: 0 8px;
                appearance: auto !important;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        .total-entries {
            letter-spacing: 0.13px;
            color: #546f63;
            opacity: 1;
            font-size: $font-size;
            font-weight: 500;
            text-align: left;
            height: 34px;
            display: $display-flex;
            align-items: $align-items-center;
        }

        .navigation {
            float: right;
            margin-bottom: 0;

            .page-item {
                margin: 0 8px;

                .page-link {
                    color: #7c978a;
                    font-size: $font-size;
                    font-weight: 500;
                    border: $border-none;
                    cursor: pointer;
                }

                &.active {
                    .page-link {
                        border: 1px solid #2dd695;
                        border-radius: 2px;
                        background: #2dd695;
                        color: #fff;
                        cursor: default;
                    }
                }
            }

            .page-item.prev,
            .page-item.next {
                .page-link {
                    border-radius: 2px;
                    background: #b5edd1;
                    border: $border-none;

                    i {
                        color: #009e4f;
                        font-size: $font-size;
                    }
                }

                &.disabled {
                    .page-link {
                        background: #dddede;
                        border: 1px solid #dddede;

                        i {
                            color: #898686;
                        }
                    }
                }
            }
        }
    }

    .card {
        border: $border-none;
        border-radius: 4px;

        .card-header {
            background-color: #fff;
            border-color: #f0edee;

            .priority-label {
                background: #fff5f5;
                border-radius: 4px;
                color: $red-color;
                font-size: 10px;
                padding: 6px;
                text-transform: capitalize;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;

                i {
                    margin-right: 4px;
                }
            }

            .v-title {
                margin: 0 10px 15px;

                &.project-header {
                    h2 {
                        font-size: 12px !important;

                        &.name {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            white-space: normal !important;
                        }
                    }

                    p {
                        font-size: 10px !important;
                    }
                }

                h2 {
                    letter-spacing: 0px;
                    color: $black-color;
                    opacity: 1;
                    font-size: 15px;
                    font-weight: 500;
                    text-align: left;
                    margin-bottom: 0px;
                    white-space: nowrap;

                    .day {
                        color: #fff;
                        font-weight: 400;
                        font-size: 13px;
                        background-color: #8b0000;
                        border-radius: 4px;
                        padding: 2px;
                        display: inline-block;
                        width: 35px;
                    }

                    a {
                        color: inherit;
                        text-decoration: none;
                        font-size: $font-size;
                        font-weight: $font-weight-m;
                    }

                    img {
                        height: 15px;
                        margin-right: 6px;
                    }

                    i {
                        font-size: 12px;
                        margin-right: 5px;

                        &.general {
                            color: #d6a92d;
                        }
                    }
                }

                p {
                    letter-spacing: 0px;
                    color: #5e3737b8;
                    opacity: 1;
                    font-size: $font-size;
                    font-weight: 500;
                    margin-bottom: 0;
                    white-space: nowrap;
                    margin-right: 8px;
                }

                a {
                    letter-spacing: 0.13px;
                    color: #2d88d6;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 13px;
                    font-weight: 500;

                    img {
                        height: 26px;
                        margin-left: 4px;
                    }

                    &.hover {
                        letter-spacing: 0.13px;
                        color: #425b50;
                        opacity: 1;
                        font-size: $font-size;
                        font-weight: 500;
                        white-space: nowrap;

                        &:hover {
                            color: #2d88d6;
                        }

                        img {
                            height: 22px;
                        }
                    }
                }

                .chat-btn {
                    background: #fff5f5;
                    border-radius: 4px;
                    border: none;
                    padding: 8px;
                    margin: 0 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: 14px;
                    }
                }

                .status-btn {
                    border-radius: 2px;
                    padding: 2px 4px;
                    letter-spacing: 0px;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 500;
                    border: none;
                    width: auto;
                    margin: 0;
                    text-transform: capitalize;
                    background-color: #fee3e3;
                    color: $black-color;
                    height: 22px;
                    cursor: default;
                    white-space: nowrap;

                    &:empty {
                        background-color: transparent !important;
                    }

                    &.created {
                        background: #f1e2fb;
                    }

                    &.overdue {
                        background: #fcd8d8;
                    }

                    &.inreview,
                    &.in-review {
                        background: #ffebd8;
                    }

                    &.active {
                        background: #f7dff0;
                    }

                    &.autoaccepted {
                        background: #d6e9f2;
                    }

                    &.reopened,
                    &.reopenrequested {
                        background: #ffe9fa;
                    }

                    &.inactive {
                        background: #ffbaba;
                    }

                    &.accepted {
                        background: #e1f7df;
                    }

                    &.underprogress {
                        background: #e2fbfb;
                    }

                    &.autoaccepted {
                        background: #d6e9f2;
                    }

                    &.cancellationrequested,
                    &.cancellation-requested {
                        background: #ffe5bd;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .card-footer {
            background-color: #fff;
            border-color: #ecf0e9;

            .cancel-btn {
                border: 1px solid #9bb7aa;
                border-radius: 4px;
                opacity: 1;
                letter-spacing: 0.13px;
                color: #7c978a;
                font-size: $font-size;
                font-weight: $font-weight-m;
                background-color: #fff;
                width: 120px;
                padding: 8px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .send-btn {
                background: $red-color;
                border: 1px solid $red-color;
                border-radius: 8px !important;
                opacity: 1;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                width: 110px;
                padding: 8px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        &.analysis {
            min-height: 96%;
            max-height: 96%;
            height: 96%;

            .title {
                letter-spacing: 0px;
                color: #000000;
                font-size: 22px;
                font-weight: $font-weight-m;
                text-align: left;
            }

            .sub-title,
            p {
                letter-spacing: 0px;
                color: #7c828a;
                font-size: 13px;
                font-weight: 400;
                text-align: left;
                margin-bottom: 4px;
            }

            p {
                i {
                    font-size: 16px;
                    margin-right: 4px;
                }

                &.increase {

                    span,
                    i {
                        color: #33a244;
                    }
                }

                &.decrease {

                    span,
                    i {
                        color: #a23333;
                    }
                }
            }
        }

        .chart-datepick {
            display: $display-flex;
            align-items: $align-items-center;
            justify-content: end;

            input {
                border: 1px solid #dce2e3;
                border-radius: $border-radius;
                color: $black-color;
                font-size: 12px;
                font-weight: 500;
                width: 100px;
                background-color: #fff !important;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            svg {
                left: -4px;
                top: 0px;
                position: absolute;
                padding: 0.5rem;
                box-sizing: content-box;
            }
        }

        .react-datepicker-popper {
            z-index: 9999;
        }

        .react-datepicker__navigation {
            width: 8% !important;
            background: transparent !important;

            .react-datepicker__navigation-icon {
                top: 8px !important;
            }
        }

        &.widget {
            .text {
                letter-spacing: 0px;
                color: #120303;
                opacity: 1;
                font-size: 16px;
                font-weight: $font-weight-m;
                padding-left: 8px;
            }

            .count {
                letter-spacing: 0px;
                color: #120303;
                opacity: 1;
                font-size: 20px;
                font-weight: 700;
                padding-left: 8px;
            }

            .showicon {
                border-right: 1px solid #e2e2e2;
                display: $display-flex;
                align-items: $align-items-center;

                img {
                    height: 36px;
                }
            }

            .header-divider {
                border-bottom: 1px solid #e2e2e2;
                margin: 5px 0;
            }
        }

        &.card-table {
            min-height: 100%;

            a {
                text-decoration: none;
                background-color: transparent;
                padding: 0;
            }

            &.cal-head {
                min-height: auto !important;

                .card-header {
                    border-radius: 4px !important;
                }
            }

            .card-header {
                letter-spacing: 0px;
                color: #120303;
                opacity: 1;
                font-size: 16px;
                font-weight: $font-weight-m;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                a {
                    float: right;
                    color: $red-color;
                    font-size: 12px;
                    font-weight: 500;
                    text-decoration: none;
                }

                .font-size-12px {
                    font-size: 12px !important;
                }
            }

            .card-body {
                overflow: auto;

                .card {
                    box-shadow: 0px 0px 4px rgb(0 0 0 / 14%);
                    display: block;

                    .card-body {
                        overflow: unset !important;
                    }
                }

                .semi-circle-chart {
                    position: relative;

                    canvas {
                        position: absolute;
                        z-index: 9;
                    }

                    .chart-content {
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, 100%);
                        top: 15px;
                        height: 70px;
                    }

                    .percent {
                        font-size: 20px;
                        font-weight: 500;
                        bottom: 68px;
                        margin-bottom: 2px;
                    }

                    .data-title {
                        font-size: 11px;
                        font-weight: 600;
                        margin-bottom: 0;
                        line-height: 16px;
                        text-align: center;
                        margin-top: 4px;
                    }

                    .left-percent {
                        position: absolute;
                        left: 5%;
                        transform: translate(-50%, 0);
                        font-size: 10px;
                        font-weight: 400;
                        bottom: 0px;
                        color: #a1a1a1;
                    }

                    .right-percent {
                        position: absolute;
                        right: -10%;
                        transform: translate(-50%, 0);
                        font-size: 10px;
                        font-weight: 400;
                        bottom: 0px;
                        color: #a1a1a1;
                    }
                }

                .table-area {
                    table {
                        td {
                            max-width: 100px;
                            min-width: 100px;
                            padding: 3px;
                        }

                        .v-title {
                            h2 {
                                white-space: break-spaces !important;

                                &.status {
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }

                .chart-datepick {
                    input {
                        width: 90px;
                        padding-right: 0;
                    }

                    p {
                        letter-spacing: 0px;
                        color: #1c0000;
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 0;

                        span {
                            color: #147ad6;
                            font-weight: $font-weight-m;
                            font-size: 13px !important;
                        }
                    }
                }

                .product-table {
                    td {
                        background-color: #f4f7f200;
                        border-bottom: 1px solid #e5eae2;

                        i {
                            font-size: 10px;
                            margin-right: 5px;
                        }
                    }
                }
            }

            &.block-t {

                .card-header,
                .card-body .card {
                    display: block !important;
                }

                .card-header {
                    span {
                        color: $red-color;
                    }
                }
            }

            .barchart-view {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: 96px;

                .data-labels {
                    display: flex;
                    width: 100%;
                    margin-bottom: 10px;

                    .label-1 {
                        display: flex;
                        align-items: center;
                        margin-right: 30px;

                        i {
                            color: #ff9392;
                            font-size: 15px;
                            margin-right: 5px;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 500;
                            color: #000000;
                            margin-bottom: 0;
                        }
                    }

                    .label-2 {
                        display: flex;
                        align-items: center;

                        i {
                            color: #ffdddd;
                            font-size: 15px;
                            margin-right: 5px;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 500;
                            color: #000000;
                            margin-bottom: 0;
                        }
                    }
                }

                .stacked-barchart {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;

                    .data-1 {
                        width: auto;
                        height: 40px;
                        background: #ff9392;
                        opacity: 1;
                    }

                    .data-2 {
                        width: auto;
                        height: 40px;
                        background: #ffdddd;
                        opacity: 1;
                    }
                }

                .chart-data {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 10px;

                    .data-title {
                        font-size: 13px;
                        font-weight: 600;
                        margin-bottom: 0;
                        line-height: 16px;
                        text-align: center;
                    }

                    .left-percent {
                        font-size: 12px;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 0;
                    }

                    .right-percent {
                        font-size: 12px;
                        font-weight: 500;
                        color: #000000;
                        margin-bottom: 0;
                    }
                }
            }

            .analytics-chart {
                .data-labels {
                    display: flex;
                    justify-content: baseline;
                    margin: 1rem 0;
                    flex-wrap: wrap;

                    .label {
                        display: flex;
                        align-items: baseline;
                        margin-right: 30px;
                        margin-bottom: 1rem;

                        p {
                            font-size: 12px;
                            font-weight: 500;
                            color: #000000;
                            margin-bottom: 2px;
                            white-space: nowrap;
                        }

                        h2 {
                            font-size: 13px;
                            font-weight: 600;
                            color: #000000;
                            margin-bottom: 0;
                        }

                        i {
                            font-size: 15px;
                            margin-right: 5px;
                        }
                    }

                    .label-1 {
                        i {
                            color: #47b39c;
                        }
                    }

                    .label-2 {
                        i {
                            color: #75cfbc;
                        }
                    }

                    .label-3 {
                        i {
                            color: #d54831;
                        }
                    }

                    .label-4 {
                        i {
                            color: #ec7f6f;
                        }
                    }

                    .label-5 {
                        i {
                            color: #e39d21;
                        }
                    }

                    .label-6 {
                        i {
                            color: #f7c060;
                        }
                    }

                    .dataset-b {
                        width: 32px;
                        height: 12px;

                        &.total {
                            background-color: #47b39c;
                        }

                        &.overdue {
                            background-color: #d54831;
                        }

                        &.errors {
                            background-color: #f7c060;
                        }

                        &.hours {
                            background-color: #0f0f93;
                        }
                    }
                }

                &.six canvas {
                    height: 400px !important;
                }

                .canvasjs-chart-credit {
                    display: none;
                }
            }

            .table-card-list {
                overflow: auto;
                padding: 2px;

                .card {
                    .table-area {
                        table {
                            tbody {
                                a {
                                    display: contents;

                                    td {
                                        .v-title {
                                            h2 {
                                                font-weight: 600 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .add-on-title {
                letter-spacing: 0px;
                color: rgba(94, 55, 55, 0.7215686275);
                opacity: 1;
                font-size: 11px;
                font-weight: 500;
                margin-bottom: 0;
                white-space: nowrap;
            }

            .add-on-row {
                td {
                    &:first-child {
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    .accordion {
        &.card-accordion {
            .accordion-item {
                border: 0 !important;

                .accordion-header {
                    .accordion-button {
                        padding: 0;
                        background-color: transparent !important;
                        height: 48px;
                        align-items: baseline;
                        box-shadow: none !important;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &::after {
                            margin-right: 2px;
                            filter: brightness(0.5);
                            position: absolute;
                            right: 0;
                            top: 5px;
                        }
                    }
                }

                .accordion-body {
                    padding: 0;
                }

                .card-header {
                    width: 100%;
                }
            }
        }
    }

    .found-text {
        letter-spacing: 0px;
        color: #473f3f;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;

        span {
            color: #00b15c;
        }
    }

    .tab-content {
        .card {
            border: $border-none;
            border-radius: 4px;

            .card-body {
                .collapse {
                    border-top: 1.4px solid #ecf0e9;
                    padding: 15px 0;
                }

                .table-area {
                    width: 100%;
                    overflow-y: auto;
                }
            }

            .card-footer {
                background-color: #fff;
                display: $display-flex;
                align-items: $align-items-center;
                border-color: #f4f7f2;
                border-width: 1.4px;

                button {
                    letter-spacing: 0.11px;
                    color: #425b50;
                    opacity: 1;
                    font-size: $font-size;
                    font-weight: $font-weight-m;
                    text-align: center;
                    background-color: #fff;
                    border: $border-none;
                    border-radius: 0;
                    width: 50%;
                    padding: 0;

                    &:hover {
                        color: #2d88d6;
                    }

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }

                .header-divider {
                    border-right: 1.4px solid #dddede;
                    height: 20px;
                    border-top: 0;
                }
            }
        }

        .tab-pane[aria-labelledby="map-tab-pane"] {
            .v-title {
                p {
                    white-space: break-spaces;
                }

                h2 {
                    &.engine {
                        color: #b71919;
                    }

                    &.battery {
                        color: #93840e;
                    }
                }
            }

            .collapsed {
                .table-area {
                    overflow: hidden;

                    .status-icons {
                        display: none;
                    }
                }

                .collapse {
                    border-top: 0;
                    padding: 0;

                    tr {
                        &:first-child {
                            border-bottom: 1.4px solid #ecf0e9;
                        }

                        td {
                            .v-title {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .view-page {
        .card {
            .table tbody .v-title h2 {
                display: block !important;
                overflow: unset !important;
            }

            .table-area {
                &.storage {
                    .nested-div {
                        .v-title {
                            margin: 0px 10px 0px 0px;
                            flex-wrap: wrap;
                            padding: 0 3px;
                        }

                        h2 {
                            letter-spacing: 0px;
                            color: #120303;
                            opacity: 1;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: left;
                            margin-bottom: 2px;
                            line-height: 16px;
                            text-transform: math-auto;
                            white-space: nowrap;
                            overflow: hidden;
                        }

                        p {
                            letter-spacing: 0px;
                            color: rgba(94, 55, 55, 0.7215686275);
                            opacity: 1;
                            font-size: 11px;
                            font-weight: 500;
                            margin-bottom: 0;
                            white-space: nowrap;
                        }
                    }
                }

                .table {
                    tr {
                        td {
                            padding: 3px;
                        }
                    }
                }

                .revised-output-table {
                    .right-arrow-icon {
                        height: 20px;
                        object-fit: contain;
                    }
                }
            }

            .uploaded-file {
                border: $border-none;
                width: 300px;
            }

            .card-body {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }

            .card-header .v-title {
                margin-left: 0;
            }

            .close-btn {
                background-color: transparent;
                border: $border-none;
                color: $button-color;
                font-size: 12px;
                padding: 0;
                font-weight: 500;

                &:hover {
                    color: #d81010;
                }
            }

            .file-section {
                display: $display-flex;
                justify-content: space-between;
                margin-top: 27px;

                .form-label {
                    color: $black-color;
                    font-size: 13px;
                    font-weight: 400;
                }

                .file-list {
                    display: $display-flex;
                    justify-content: space-between;
                    align-items: $align-items-center;
                    background-color: #f5f5f5;
                    padding: 6px 10px;
                    width: 300px;
                    margin-bottom: 4px;
                    border-radius: $border-radius;

                    p {
                        color: $black-color;
                        margin-bottom: 0;
                        font-size: $font-size;
                        font-weight: 500;
                    }

                    .actions {
                        display: flex;
                        align-items: center;

                        span {
                            cursor: pointer;
                            margin: 0 4px;
                        }

                        img {
                            height: 12px;
                        }
                    }
                }

                .file-table {
                    table {
                        .text-center {
                            text-align: center;
                        }

                        thead {
                            border-bottom: transparent;

                            tr {
                                th {
                                    color: #0e0000;
                                    font-size: 13px;
                                    font-weight: 400;
                                    vertical-align: middle;
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    color: #0e0000;
                                    margin-bottom: 0;
                                    font-size: 13px;
                                    font-weight: 500;
                                    vertical-align: middle;

                                    .actions {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        span {
                                            cursor: pointer;
                                            margin: 0 4px;

                                            img {
                                                height: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .file-bottom-btn {
                    display: $display-flex;
                    align-items: end;
                    justify-content: end;
                }
            }

            .send-btn {
                background: $red-color;
                border-radius: $border-radius;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                width: 110px;
                border: $border-none;
                padding: 8.67px;
                height: 30px;
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .add-btn {
                background: #46bf6b;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                width: 100%;
                border: $border-none;
                height: 34px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .price-add-btn {
                background: #46bf6b;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                width: 50%;
                border: $border-none;
                height: 34px;
                margin-top: 24px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .other-service-add-btn {
                background: #46bf6b;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                width: 100%;
                border: $border-none;
                height: 34px;
                margin-top: 27px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .upload-btn {
                background-color: #9e9e9e;
                color: #fff;
                border: $border-none;
                font-size: 12px;
                font-weight: 500;
                border-radius: 4px;
                width: 110px;
                height: 34px;
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;

                &.active {
                    background-color: #46bf6b;

                    &:hover {
                        background-color: #39a159de;
                    }
                }

                img {
                    height: 14px;
                    margin-right: 4px;
                }
            }

            .reset-btn {
                background: #fff2f2;
                border-radius: $border-radius;
                letter-spacing: 0.13px;
                color: $button-color;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                width: 110px;
                border: $border-none;
                padding: 8.67px;
                height: 30px;
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .pricing-table {
                margin: 15px 0;
                border-collapse: separate;
                border-spacing: 0 4px;

                thead {
                    th {
                        color: $black-color;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 0 0.5rem;
                        border-bottom: unset;
                    }
                }

                tbody {
                    tr {
                        margin: 5px 0;

                        td {
                            background: #f5f5f5;
                            color: #000035;
                            font-size: 13px;
                            font-weight: 500;
                            vertical-align: middle;

                            &:first-child {
                                border-radius: 8px 0 0 8px;
                            }

                            &:last-child {
                                border-radius: 0px 8px 8px 0px;
                            }

                            button {
                                background-color: transparent;
                                padding: 0;
                                border: none;
                                margin: 0 5px;

                                img {
                                    height: 15px;
                                }

                                i {
                                    color: #9e9e9e;
                                    font-size: 15px;
                                }

                                a {
                                    color: inherit;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }

            .added-table {
                overflow: auto;
                display: block;
                height: 300px;
                background-color: #fff;

                table {
                    thead {
                        position: sticky;
                        top: 0;
                        z-index: 9999999999;

                        th {
                            padding: 10px;
                            background-color: #fff;
                        }
                    }
                }
            }

            .image-input {
                background: #fff;
                border: 1px dashed #a7a7a7;
                border-radius: 4px;
                text-align: center;
                width: 450px;

                input {
                    display: none;
                }

                label {
                    align-items: $align-items-center;
                    color: #878080;
                    cursor: pointer;
                    display: $display-flex;
                    flex-direction: column;
                    font-size: 13px;
                    font-weight: 400;
                    height: 80px;
                    justify-content: center;

                    span {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    &.contract {
                        height: 35px !important;
                    }
                }
            }

            .input-text-width {
                width: 139px;
            }

            .input-text {
                margin-bottom: 1rem;
            }

            .sub-header {
                letter-spacing: 0px;
                color: $black-color;
                opacity: 1;
                font-size: 15px;
                font-weight: 500;
                text-align: left;
                margin-bottom: 0px;
                white-space: nowrap;
            }

            .header-divider {
                margin: 0.2rem 0 1rem;
                border-bottom: 1px solid #d4d4d4;
            }

            .form-label {
                text-align: left;
                letter-spacing: 0px;
                color: $black-color;
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 4px;
            }

            .form-control,
            .rmsc .dropdown-container {
                background-color: #f5f5f5;
                border-radius: $border-radius;
                letter-spacing: 0.13px;
                color: #201f1f;
                border: $border-none;
                font-size: 12px;
                font-weight: $font-weight-m;
                padding: 9.34px;

                &[type="date"] {
                    text-transform: uppercase;

                    &[value=""] {
                        font-weight: 400 !important;
                        color: #6c7484;
                    }
                }

                &::placeholder {
                    font-weight: 400 !important;
                    font-size: 12px;
                }

                &:focus,
                &:focus-within {
                    box-shadow: none !important;
                    outline: none !important;
                    border: none !important;
                }
            }

            .form-control:not(textarea),
            .rmsc .dropdown-container {
                height: 34px;
            }

            .rmsc .dropdown-container {
                padding: 0 !important;

                &:focus {
                    border: none !important;
                }

                .dropdown-content {
                    accent-color: $red-color;
                    z-index: 99999;

                    input[type="checkbox"] {
                        position: relative;
                        top: 2px;
                    }
                }

                .dropdown-heading-value span {
                    color: #201f1f;
                    border: none;
                    appearance: auto;
                    font-size: 12px;
                    font-weight: 600;

                    &.gray {
                        color: #6f7783 !important;
                        font-weight: 400 !important;
                    }
                }
            }

            .rmsc:not(.multi-select) {
                .clear-selected-button {
                    display: none;
                }
            }

            select {
                padding-left: 5.6px;
                filter: saturate(0.5);

                option:hover {
                    background-color: $red-color !important;
                }

                &[placeholder="-1"],
                &[placeholder="undefined"] {
                    font-weight: 400 !important;
                    font-size: 12px;
                    color: #6c7484;
                }

                option {
                    color: #201f1f;

                    &[value="-1"] {
                        color: #6c7484;
                    }
                }
            }

            .delete-button {
                display: $display-flex;
                align-items: $align-items-center;
                margin-bottom: 0;
                height: 100%;

                button {
                    width: 45px;
                    margin-top: 0.7rem;
                    display: $display-flex;
                    align-items: $align-items-center;
                    justify-content: center;

                    &:hover {
                        background-color: #f9f2f2;
                    }

                    img {
                        height: 15px;
                    }
                }
            }

            .form-check {
                display: flex;
                align-items: baseline;

                input {
                    border-color: $red-color;
                    width: 13px;
                    height: 13px;
                    margin-right: 6px;
                    margin-top: 0px;
                    position: relative;
                    top: 3px;

                    &:checked {
                        background-color: $red-color;
                        border-color: $red-color;
                    }

                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border-color: $red-color;
                    }
                }

                label {
                    text-align: left;
                    letter-spacing: 0px;
                    color: $black-color;
                    font-weight: 500;
                    font-size: 13px;
                }
            }

            .toggle-btn {
                display: $display-flex;
                align-items: $align-items-center;
                position: absolute;
                right: 15px;

                p {
                    letter-spacing: 0px;
                    color: #003ed9;
                    opacity: 1;
                    font-size: 11px;
                    margin-right: -6px;
                    font-weight: 400;
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                .extend-btn {
                    cursor: pointer;
                    text-decoration: underline;
                    font-weight: 600;
                }

                .nav {
                    margin-left: 10px;

                    .nav-item {
                        width: 100% !important;

                        &:first-child {
                            .nav-link {
                                &.active {
                                    border-radius: 8px 0px 0px 8px;
                                    margin-left: 6px;
                                    position: relative;
                                    padding-right: 0px;
                                }
                            }
                        }

                        &:last-child {
                            .nav-link {
                                &.active {
                                    margin-left: -6px;
                                    border-radius: 0px 8px 8px 0px;
                                    padding-left: 4px;
                                    padding-right: 10px;
                                }
                            }
                        }

                        .nav-link {
                            border: 1px solid #d19999;
                            border-radius: $border-radius;
                            letter-spacing: 0px;
                            color: $black-color;
                            font-size: 11px;
                            padding: 5px 6px;
                            margin: 0;
                            width: 100%;
                            white-space: nowrap;

                            &.active {
                                background: #a23333;
                                border: 1px solid #d19999;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .trial-msg {
                p {
                    color: #003ed9;
                    font-size: 11px;
                    font-weight: 400;
                    margin-bottom: 0;

                    .extend-btn {
                        color: #910e0e;
                        cursor: pointer;
                        text-decoration: underline;
                        font-weight: 600;
                    }
                }

                .trial-expired {
                    color: #003ed9;
                    font-size: 11px;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }
        }

        .report-error {
            letter-spacing: 0px;
            color: $red-color;
            opacity: 1;
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 0;
            white-space: nowrap;
            cursor: pointer;
            text-decoration: underline;
        }

        .template {
            .reset-btn {
                font-weight: 500;
                width: 110px;
                color: #9c2d32;
                border-radius: $border-radius;
                font-size: 12px;
                padding: 8.67px;
                background: #fff2f2;
                border: none;
                height: 30px;
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;
            }

            .send-btn {
                background: $red-color;
                border-radius: $border-radius;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                width: 110px;
                border: $border-none;
                padding: 8.67px;
                height: 30px;
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .card {
                .card-header {
                    img {
                        width: 70px;
                        height: 70px;
                    }

                    h1 {
                        letter-spacing: 0.29px;
                        color: $black-color;
                        font-size: 20px;
                        font-weight: $font-weight-m;
                        margin-bottom: 0;
                    }
                }

                .card-body {
                    table {
                        tr {
                            td {
                                .detail-bar {
                                    background: #fcfafa;
                                    width: 100%;
                                    height: 100px;
                                    padding: 0.5rem 0.5rem;
                                }

                                h4 {
                                    letter-spacing: 0px;
                                    color: $black-color;
                                    opacity: 1;
                                    font-size: 16px;
                                    font-weight: $font-weight-m;
                                    margin: 0;
                                    margin-bottom: 4px;
                                    padding: 0;
                                }

                                span {
                                    color: #5e3737 !important;
                                    font-size: 13px;
                                    font-weight: 400 !important;
                                    text-align: left;
                                    width: 30%;
                                }

                                .company {
                                    color: $black-color;
                                    font-size: 13px;
                                    font-weight: $font-weight-m;
                                    margin: 0;
                                    margin-bottom: 9px;
                                    padding: 0;
                                    display: flex;
                                }

                                .address {
                                    color: $black-color;
                                    font-size: 13px;
                                    font-weight: 400;
                                    margin: 0;
                                    margin-bottom: 4px;
                                    padding: 0;
                                }
                            }
                        }

                        &:not(.product-table) {
                            tr {
                                td {
                                    span {
                                        width: 42% !important;
                                    }
                                }
                            }
                        }
                    }

                    .terms-condition {
                        margin: 15px;

                        h4 {
                            color: #022c1d;
                            font-size: 15px;
                            font-weight: $font-weight-m;
                            margin: 0;
                            margin-bottom: 4px;
                        }

                        ul {
                            padding: 0px 22px;
                            padding-bottom: 0;
                            margin-bottom: 0;

                            li {
                                color: #022c1d;
                                font-size: 15px;
                                font-weight: $font-weight-m;
                                text-align: left;
                                margin-bottom: 10px;

                                &::marker {
                                    font-size: 22px;
                                    color: #022c1d;
                                }
                            }
                        }

                        ol {
                            margin-top: 12px;

                            li {
                                color: #5e5e5e !important;
                                font-size: 14px;
                                font-weight: 400;
                                text-align: left;

                                &::marker {
                                    font-size: 14px !important;
                                }
                            }
                        }

                        p {
                            color: #5e5e5e !important;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            text-align: justify;
                            padding-top: 10px;
                        }
                    }
                }

                .card-footer {
                    border-color: #d4d4d4;

                    p {
                        text-align: center;
                        color: #022c1d;
                        font-size: 13px;
                        font-weight: $font-weight-m;
                        margin-bottom: 0;
                    }
                }

                .rating {
                    display: $display-flex;
                    flex-direction: column;
                    align-items: $align-items-center;
                    justify-content: center;

                    img {
                        height: 62px;
                    }

                    h1 {
                        font-size: 64px;
                        color: #061d36;
                        font-weight: $font-weight-m;
                        margin: 10px 0;
                    }
                }

                .comments {
                    letter-spacing: 0px;
                    color: #120303;
                    font-size: 12px;
                    font-weight: 500;
                }

                .check-div {
                    display: $display-flex;
                    align-items: flex-start;
                    margin: 15px 0;

                    img {
                        height: 12px;
                        margin-top: -4px;
                    }

                    button {
                        padding: 0;
                        margin: -2px 12px 0;
                    }
                }
            }

            .product-table {
                margin-top: 15px;

                thead {
                    th {
                        background: #e8cccc;
                        letter-spacing: 0.13px;
                        color: #022c1d;
                        font-size: $font-size;
                        font-weight: $font-weight-m;
                        padding: 10px;
                        border-width: 1px;
                        border-color: #fcfcfc;
                        white-space: nowrap;
                    }
                }

                tbody {

                    td,
                    h2 {
                        letter-spacing: 0.13px;
                        color: $black-color;
                        font-size: 12px;
                        font-weight: 400;
                        border-color: #e5eae2;
                        white-space: nowrap;
                        min-width: 100% !important;
                        max-width: 100% !important;
                        margin-bottom: 0;
                        vertical-align: middle;
                        line-height: 20px;

                        // &:nth-of-type(2) {
                        //     h2 {
                        //         min-width: 100px !important;
                        //         max-width: 100px !important;
                        //     }
                        // }
                        // &:nth-of-type(3) {
                        //     h2 {
                        //         min-width: 400px !important;
                        //         max-width: 400px !important;
                        //     }
                        // }
                        h2 {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            margin-bottom: 0 !important;
                            white-space: normal !important;
                            min-width: 200px !important;
                            max-width: 200px !important;
                        }

                        &:first-child {
                            max-width: 32px;
                        }

                        &.status {
                            text-transform: capitalize;
                        }

                        .detail-bar {
                            height: auto !important;

                            p {
                                white-space: break-spaces;
                            }
                        }

                        .red-color {
                            color: $red-color !important;
                        }
                    }

                    .add-on-title {
                        letter-spacing: 0px;
                        color: rgba(94, 55, 55, 0.7215686275);
                        opacity: 1;
                        font-size: 11px;
                        font-weight: 500;
                        margin-bottom: 0;
                        white-space: nowrap;
                    }

                    .add-on-row {
                        td {
                            &:first-child {
                                padding-left: 30px;
                            }
                        }
                    }

                    td:nth-of-type(15) {
                        width: 150px !important;
                        max-width: 150px !important;
                        min-width: 150px !important;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &.added-service-table {
                    .form-check {
                        label {
                            color: #0e000047;
                        }

                        input[type="checkbox"]:checked~label {
                            color: #0e0000 !important;
                        }
                    }

                    input[type="text"] {
                        width: 120px;
                        background-color: #fff;
                        border: 1px solid $red-color !important;

                        &[disabled] {
                            background-color: #f5f5f5;
                            border: none !important;
                        }
                    }

                    tbody {
                        tr {
                            td:not(:first-child) {
                                min-width: 15% !important;
                                max-width: 15% !important;
                            }
                        }
                    }
                }
            }

            &.small {
                &.profile {
                    .card {
                        min-height: 94%;
                        max-height: 100%;

                        input[disabled] {
                            cursor: not-allowed;
                            color: light-dark(rgb(84 84 84 / 54%), rgb(170, 170, 170));
                        }

                        .tab-head {
                            letter-spacing: 0;
                            color: #0e0000;
                            opacity: 1;
                            margin-bottom: 4px;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        .btn-default {
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .card-header {
                    h1 {
                        font-size: 20px !important;
                    }
                }

                .card-body {
                    h4 {
                        letter-spacing: 0.29px;
                        color: $black-color;
                        font-size: 15px;
                        font-weight: $font-weight-m;
                        margin-bottom: 0;
                    }

                    .header-divider {
                        margin: 0.2rem 0 1rem;
                        border-bottom: 1px solid #d4d4d4;
                    }

                    .rate-experience {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .rate-selection {
                            display: $display-flex;
                            align-items: $align-items-center;
                            justify-content: flex-start;

                            button {
                                margin: 4px 8px 4px 0;
                                border-radius: 4px;
                                font-size: 16px;
                                font-weight: 500;
                                padding: 5px 15px;

                                &.one {
                                    background: #ffd1d1;
                                    border: 1px solid #e43e3d;
                                    color: #e43e3d;

                                    &.active {
                                        background: #e43e3d;
                                        border: 1px solid #e43e3d;
                                        color: #fff;
                                    }
                                }

                                &.two {
                                    background: #ffd8d2;
                                    border: 1px solid #ec654e;
                                    color: #ec654e;

                                    &.active {
                                        background: #ec654e;
                                        border: 1px solid #ec654e;
                                        color: #fff;
                                    }
                                }

                                &.three {
                                    background: #ffe0ce;
                                    border: 1px solid #ef874c;
                                    color: #ef874c;

                                    &.active {
                                        background: #ef874c;
                                        border: 1px solid #ef874c;
                                        color: #fff;
                                    }
                                }

                                &.four {
                                    background: #ffeacf;
                                    border: 1px solid #f3a74c;
                                    color: #f3a74c;

                                    &.active {
                                        background: #f3a74c;
                                        border: 1px solid #f3a74c;
                                        color: #fff;
                                    }
                                }

                                &.five {
                                    background: #ffefc6;
                                    border: 1px solid #f8c43d;
                                    color: #f8c43d;

                                    &.active {
                                        background: #f8c43d;
                                        border: 1px solid #f8c43d;
                                        color: #fff;
                                    }
                                }

                                &.six {
                                    background: #fff5c4;
                                    border: 1px solid #e1c63b;
                                    color: #e1c63b;

                                    &.active {
                                        background: #e1c63b;
                                        border: 1px solid #e1c63b;
                                        color: #fff;
                                    }
                                }

                                &.seven {
                                    background: #faffb9;
                                    border: 1px solid #c1cc36;
                                    color: #c1cc36;

                                    &.active {
                                        background: #c1cc36;
                                        border: 1px solid #c1cc36;
                                        color: #fff;
                                    }
                                }

                                &.eight {
                                    background: #ecffbd;
                                    border: 1px solid #9fcd35;
                                    color: #9fcd35;

                                    &.active {
                                        background: #9fcd35;
                                        border: 1px solid #9fcd35;
                                        color: #fff;
                                    }
                                }

                                &.nine {
                                    background: #ddffbd;
                                    border: 1px solid #7fcd31;
                                    color: #7fcd31;

                                    &.active {
                                        background: #7fcd31;
                                        border: 1px solid #7fcd31;
                                        color: #fff;
                                    }
                                }

                                &.ten {
                                    background: #cfffb4;
                                    border: 1px solid #5aaf2b;
                                    color: #5aaf2b;

                                    &.active {
                                        background: #5aaf2b;
                                        border: 1px solid #5aaf2b;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    .rating-exp {
                        display: $display-flex;
                        flex-direction: column;
                        align-items: $align-items-center;
                        justify-content: $align-items-center;

                        .rate-desc {
                            color: $black-color;
                            font-size: $font-size;
                            font-weight: $font-weight-m;
                            margin-bottom: 4px;
                            margin-top: 2rem;
                        }

                        img {
                            height: 50px;
                            margin-right: 30px;
                        }
                    }

                    .customer-services {
                        display: $display-flex;
                        align-items: $align-items-center;
                        flex-wrap: wrap;
                        margin-bottom: 1rem;

                        .service-btn {
                            font-weight: 500;
                            width: auto;
                            color: $black-color;
                            font-size: 12px;
                            padding: 8.67px;
                            background: #ffffff;
                            border: 1px solid #e5e8ea;
                            border-radius: 4px;
                            height: 38px;
                            display: $display-flex;
                            align-items: $align-items-center;
                            justify-content: $align-items-center;
                            margin-right: 6px;
                            margin-bottom: 6px;

                            &:first-child {
                                margin-left: 0;
                            }

                            &.reset-btn {
                                background: #fff2f2 !important;
                                color: $red-color !important;
                            }
                        }
                    }
                }
            }

            .role-list {
                display: $display-flex;
                overflow-x: auto;

                .role-box {
                    margin-right: 10px;
                    margin-bottom: 10px;

                    img {
                        height: 16px;
                        width: auto;
                    }
                }

                .swiper {
                    overflow-y: auto !important;
                    margin-left: unset !important;
                    margin-right: unset !important;
                    cursor: move;

                    .swiper-slide {
                        width: auto !important;
                    }
                }

                .card {
                    background: none;
                    width: 250px;

                    .card-header,
                    .card-body {
                        background-color: #fff;
                        border-radius: $border-radius;
                    }

                    .card-header {
                        border-left: 5px solid $red-color;
                        padding: 10px;

                        .v-title {
                            margin-bottom: 0;
                        }
                    }

                    .card-body {
                        margin-top: 5px;
                        height: 325px;
                        overflow: auto;

                        .form-check {
                            margin-bottom: 5px;
                        }
                    }
                }
            }

            .profile-info {
                display: $display-flex;
                align-items: $align-items-center;
                flex-direction: column;
                padding: 20px 0;

                img {
                    &.profile {
                        border-radius: $border-radius;
                        border: 6px solid $red-color;
                        height: 170px;
                        width: 170px;
                        object-fit: cover;
                    }
                }

                .image-input {
                    border: none !important;
                    width: 170px;
                    position: relative;

                    .image-button {
                        position: absolute;
                        right: -12px;
                        bottom: -12px;
                        z-index: 1;

                        img {
                            height: 34px;
                        }
                    }
                }

                h4 {
                    letter-spacing: 0px;
                    color: #000035 !important;
                    font-weight: $font-weight-m !important;
                    font-size: 16px !important;
                    margin: 15px 0;
                }
            }

            .profile-tabs {
                &.nav-tabs {
                    border: none;

                    .nav-item {
                        .nav-link {
                            background-color: #fff !important;
                            color: $black-color;
                            font-size: 13px;
                            font-weight: 500;
                            border: none;
                            border-radius: 0;
                            background-size: 14px !important;
                            background-position: 13px 13px !important;
                            padding-left: 36px;
                            width: 100%;
                            text-align: left;

                            &.active {
                                background-color: #f9f2f2 !important;
                                color: $red-color;
                            }

                            &[aria-controls="profile-tab-pane"] {
                                background: url(../images/navbar/prof-tab.png) left center/contain no-repeat;
                            }

                            &[aria-controls="settings-tab-pane"] {
                                background: url(../images/navbar/setting-tab.png) left center/contain no-repeat;
                            }

                            &[aria-controls="password-tab-pane"] {
                                background: url(../images/navbar/lock-pwd.png) left center/contain no-repeat;
                                background-size: 12px !important;
                            }
                        }
                    }
                }
            }
        }

        .area-text {
            .bottom-icons {
                background: #f5f5f5 !important;
                border-radius: 4px !important;

                .btn {
                    padding-top: 4px !important;
                    padding-bottom: 4px !important;
                    padding-left: 8px;
                    padding-right: 8px;
                    border-radius: 4px;
                    margin-right: 8px;

                    &.active {
                        i {
                            color: #4d4242;
                        }
                    }
                }
            }
        }
    }

    .calendar-list {
        display: $display-flex;
        overflow-x: auto;
        position: relative;

        .cal-box {
            margin-right: 10px;
            margin-bottom: 10px;

            img {
                height: 16px;
                width: auto;
            }

            .list-block {
                margin: 6px 0;

                p {
                    letter-spacing: 0px;
                    color: #5e3737b8;
                    opacity: 1;
                    font-size: 10px;
                    font-weight: 500;
                    margin-bottom: 0;
                    white-space: nowrap;
                }

                h2 {
                    letter-spacing: 0px;
                    color: #120303;
                    opacity: 1;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: left;
                    margin-bottom: 4px;
                    line-height: 20px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }

        .card {
            background: none;
            width: 250px;

            &[day="Sun"] {
                .card-body {
                    background-color: rgb(248 248 248 / 83%) !important;
                    box-shadow:
                        rgba(50, 50, 93, 0.02) 0px 0px 0px 1px,
                        rgba(0, 0, 0, 0.05) 1px 1px 2px 0px;
                }
            }

            .card-header,
            .card-body {
                background-color: #fff;
                border-radius: $border-radius;
            }

            .card-header {
                border-left: 5px solid $red-color;
                padding: 10px;
                position: sticky;
                top: 0;

                .v-title {
                    margin-bottom: 0;
                }
            }

            .card-body {
                margin-top: 0.5rem;
                height: auto;
                overflow: hidden;
                height: 225px;

                &:hover {
                    background-color: #fffcfc;
                    overflow-y: scroll;
                }

                &:has(.no-data) {
                    cursor: default !important;

                    &:hover {
                        background-color: #fff;
                    }
                }
            }

            .month-date {
                letter-spacing: 0px;
                color: #120303;
                opacity: 1;
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                line-height: 20px;
                margin-top: -5px;
            }

            .status-box {
                border-radius: 0px 8px 0px 0px;
                padding: 5px;
                margin-top: -6px;
                float: right;
                width: 140px;
                margin-right: -18px;
                height: 20px;
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;

                p {
                    letter-spacing: 0px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #5e3737;
                    margin-bottom: 0;
                    text-align: center;
                }

                &.overdue {
                    background-color: #fcd8d8;
                }

                &.review {
                    background-color: #ffebd8;
                }

                &.delay {
                    background-color: #d8e5f1;
                }

                &.progres {
                    background-color: #e2fbfb;
                }

                &.ontime {
                    background-color: #e1f7df;
                }

                &.delayed {
                    background-color: #d8e5f1;
                }

                &.autoaccepted {
                    background: #d6e9f2;
                    color: #3891b9;
                }

                &.reopened,
                &.reopenrequested {
                    background: #ffe9fa;
                    color: #bf4ea5;
                }

                &.error,
                &.errorreported {
                    background: #ffbaba;
                    color: #9f0e0e;
                }
            }

            &.overdue,
            &.Overdue {
                .card-header {
                    border-left-color: #fd6262;
                }
            }

            &.review,
            &.Review,
            &.CustomerReview {
                .card-header {
                    border-left-color: #d99554;
                }
            }

            &.progres,
            &.UnderProgres {
                .card-header {
                    border-left-color: #c665bb;
                }
            }

            &.ontime,
            &.Ontime {
                .card-header {
                    border-left-color: #38c195;
                }
            }

            &.delay,
            &.Delay {
                .card-header {
                    border-left-color: #3d92e7;
                }
            }
        }

        .swiper {
            overflow: hidden !important;
            margin-left: unset !important;
            margin-right: unset !important;
            height: 100vh;

            &.swiper-vertical>.swiper-wrapper {
                flex-direction: row;
            }

            .swiper-slide {
                width: auto !important;
            }

            .swiper-wrapper {
                height: auto !important;
                cursor: move;
            }
        }

        .swiper-arrows {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            position: absolute;
            top: 13px;
            z-index: 99;
            width: 100%;

            button {
                position: absolute;
                background: #faf2f3;
                border: none;
                border-radius: 2px;
                padding: 2px;
                outline: none;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.5s;
                filter: drop-shadow(0 0px 3px #b9b4b4);

                i {
                    font-size: 12px;
                }

                &:hover {
                    i {
                        transform: scale(1.1);
                    }
                }

                &.disabled {
                    cursor: not-allowed;
                    background: #e1e1e1;
                    visibility: hidden;
                }
            }

            .arrow__prev,
            .warrow__prev,
            .darrow__prev {
                left: -14px;
            }

            .arrow__next,
            .warrow__next,
            .darrow__next {
                right: -14px;
            }
        }
    }

    .tab-pane[aria-labelledby="week-tab-pane"] {
        .calendar-list {
            height: 100vh;

            .cal-box {
                margin-right: 3px !important;

                .card {
                    .card-header {
                        border-left: 0 !important;
                        border-radius: 4px;

                        cursor: pointer;

                        h2 {
                            text-align: center;
                        }
                    }

                    .card-body {
                        margin-right: 6px !important;
                        padding-top: 0;
                        overflow: hidden;
                        height: 225px;
                    }
                }

                &:first-child {
                    .card-header {
                        // border-radius: 8px 0 0 8px !important;
                    }
                }

                &:last-child {
                    .card-header {
                        // border-radius: 0 8px 8px 0px !important;
                    }
                }
            }
        }
    }

    .tab-pane[aria-labelledby="month-tab-pane"] {
        .calendar-list {
            .card {
                .status-box {
                    margin-top: -10px;
                }

                .card-header {
                    border-left: 0 !important;
                }
            }
        }
    }

    .month-year {
        color: #0e0000;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .date-pick {
        display: $display-flex;
        cursor: pointer;

        a {
            background: #f9f9f9;
            border: 0.5px solid #cacaca;
            padding: 5px 10px;

            &.left {
                border-radius: 8px 0px 0px 8px;
            }

            &.right {
                border-radius: 0px 8px 8px 0px;
            }

            i {
                color: #878080;
                font-size: 14px;
            }
        }

        input {
            background: #f9f9f9 0% 0% no-repeat padding-box;
            border: 0.5px solid #cacaca;
            padding: 7.5px 10px;
            color: $black-color;
            font-size: 13px;
            font-weight: 500;

            &:focus {
                box-shadow: none;
                outline: none;
            }

            &.day-calendar {
                width: 100px;
            }
        }
    }

    .tab-pane {
        .calendar-list {
            display: initial !important;
        }
    }
}

.top-divider {
    border-top: 1px solid #f4f7f2;
    margin: 15px 0;
}

.delete-btn {
    background-color: $red-color !important;
    border: 1px solid $red-color !important;
    border-radius: $border-radius !important;
    opacity: 1 !important;
    letter-spacing: 0.13px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    height: 30px;
    padding: 8px;
    display: $display-flex;
    align-items: $align-items-center;
    justify-content: $align-items-center;

    &:hover,
    &:first-child:active,
    &:active {
        background-color: $red-color !important;
        border-color: $red-color !important;
        color: #fff;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.reopen-btn {
    background-color: #fff;
    border: 1px solid $red-color !important;
    border-radius: $border-radius !important;
    color: $red-color;
    font-size: 12px;
    font-weight: 500;
    width: 100%;

    &:hover,
    &:first-child:active,
    &:active {
        background-color: #fff !important;
        border-color: $red-color !important;
        color: $red-color;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.static-modal {
    .modal-content {
        box-shadow: 0px 3px 6px #03075739;
        border-radius: 6px;

        .modal-header {
            padding: 15px 15px 0;
            border-bottom: 0;

            h5 {
                letter-spacing: 0.14px;
                color: #022c1d;
                text-transform: capitalize;
                opacity: 1;
                font-size: 16px;
                font-weight: $font-weight-m;
                text-align: left;
            }

            .btn-close {
                display: none;
            }
        }

        .modal-body {
            .command-data {
                p {
                    letter-spacing: 0.13px;
                    color: #022c1d;
                    opacity: 1;
                    font-size: $font-size;
                    font-weight: 500;
                    margin-bottom: 4px;

                    span {
                        letter-spacing: 0.13px;
                        color: #022c1d;
                        font-weight: $font-weight-m;
                        margin-left: 4px;
                    }
                }

                .form-label {
                    letter-spacing: 0.13px;
                    color: #022c1d;
                    text-transform: capitalize;
                    opacity: 1;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: left;
                }

                textarea {
                    background: #f4f7f2;
                    border-radius: 4px;
                    border: $border-none;
                    opacity: 1;
                    color: #000;
                    font-size: 12px;
                    font-weight: $font-weight-m;

                    &::placeholder {
                        letter-spacing: 0.13px;
                        color: #7c978a;
                        font-weight: 400 !important;
                    }

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }

            .nearby-place {
                p {
                    letter-spacing: 0.13px;
                    color: #2d88d6;
                    opacity: 1;
                    font-size: $font-size;
                    font-weight: 500;
                }

                label {
                    letter-spacing: 0.11px;
                    color: #425b50;
                    opacity: 1;
                    font-size: 12px;
                    font-weight: $font-weight-m;

                    &.max-label {
                        float: right;
                        text-align: right;
                    }
                }

                .places-list {
                    display: $display-flex;
                    flex-wrap: wrap;
                    margin: 20px 0;

                    .place {
                        margin: 10px 20px;
                        display: $display-flex;
                        flex-direction: column;
                        align-items: $align-items-center;

                        p {
                            letter-spacing: 0.13px;
                            color: #022c1d;
                            opacity: 1;
                            font-size: $font-size;
                            font-weight: $font-weight-m;
                            margin: 5px 0;
                        }

                        img {
                            width: 56px;
                            height: 56px;
                        }
                    }
                }
            }
        }

        .modal-footer {
            border: $border-none;
            padding-top: 0;

            .cancel-btn {
                border: 1px solid #9bb7aa;
                border-radius: 4px;
                opacity: 1;
                letter-spacing: 0.13px;
                color: #7c978a;
                font-size: $font-size;
                font-weight: $font-weight-m;
                background-color: #fff;
                width: 120px;
                padding: 8px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .send-btn {
                background: $red-color;
                border-radius: 4px;
                opacity: 1;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: $font-size;
                font-weight: $font-weight-m;
                border: $border-none;
                width: 120px;
                padding: 8px;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    &.near-by-modal {
        .modal-dialog {
            max-width: 450px;
        }
    }
}

.right-modal {
    overflow: hidden;

    .modal-dialog {
        right: 0;
        left: 26%;
        margin-top: 0;
        height: 100%;
        margin-bottom: 0;
        max-width: 51%;

        .modal-content {
            box-shadow: 0px 3px 6px #00000048;
            border-radius: 0;
            border: 0;
            height: 100%;
            overflow: auto;
        }

        .modal-header {
            .btn-close {
                background-color: #ffffff;
                border-radius: 2px;
                opacity: 0.9;
                margin: 0;
                font-size: 12px;
                font-weight: 500;

                &::before {
                    content: "Close";
                    left: 50px;
                    position: absolute;
                    top: 15px;
                    font-size: 16px;
                    letter-spacing: 0.11px;
                    color: #d62d2d;
                }
            }
        }

        .modal-body {
            .map-area {
                .map {
                    div {
                        width: 100% !important;
                    }
                }

                .map-details {
                    margin: 15px 0;

                    h2 {
                        letter-spacing: 0.13px;
                        color: #022c1d;
                        opacity: 1;
                        font-size: $font-size;
                        font-weight: $font-weight-m;
                        text-align: left;
                    }

                    p {
                        margin-bottom: 4px;

                        &.address {
                            letter-spacing: 0.13px;
                            color: #546f63;
                            opacity: 1;
                            font-size: $font-size;
                            font-weight: 400;
                            text-align: left;
                        }
                    }

                    .btn {
                        border-radius: 2px;
                        opacity: 1;
                        padding: 4px 10px;
                        border: $border-none;
                        font-size: 12px;
                        font-weight: $font-weight-m;
                        letter-spacing: 0.11px;
                        color: #57b14f;

                        &.moving {
                            background: #e1f7df;
                            color: #57b14f;
                        }

                        &.inactive {
                            background: #ededed;
                            color: #62676f;
                        }

                        &.idle {
                            background: #fcefda;
                            color: #fa9826;
                        }

                        &.stopped {
                            background: #fff2f2;
                            color: #f25555;
                        }
                    }

                    .status-detail {
                        display: $display-flex;
                        flex-wrap: wrap;
                        margin: 10px 0;

                        .st-box {
                            margin: 10px 25px 10px 0;

                            p {
                                letter-spacing: 0.13px;
                                color: #022c1d;
                                opacity: 1;
                                font-size: 13px;
                                font-weight: 500;
                                text-align: left;
                                margin-bottom: 0px;

                                &.engine {
                                    color: #b71919;
                                }

                                &.battery {
                                    color: #93840e;
                                }
                            }

                            label {
                                letter-spacing: 0.13px;
                                color: #546f63;
                                opacity: 1;
                                font-size: 12px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

.filter-modal {
    overflow: hidden !important;

    .modal-dialog {
        position: fixed;
        right: 0;
        margin-top: 0;
        margin-bottom: 0;
        max-width: 30%;
        top: 0;
        height: 100vh;
        min-width: 400px;

        .modal-content {
            box-shadow: 0px 3px 6px #03075739;
            border-radius: 0;
            border: 0;
            height: 100%;
            overflow: auto;

            .modal-header {
                background: $red-color;
                border-radius: unset;
                letter-spacing: 0.14px;
                opacity: 1;
                font-size: 16px;
                font-weight: 500;
                padding-top: 0.5rem !important;
                padding-bottom: 0.5rem !important;

                h5 {
                    letter-spacing: 0px;
                    color: #ffffff;
                    text-transform: capitalize;
                    text-align: left;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.4px;
                }

                .btn-close {
                    font-size: 12px;
                    filter: invert(1);
                    opacity: 1 !important;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }

            .modal-body {
                padding: 0 15px;
                overflow-y: auto;

                .top-dropdown {
                    .form-label {
                        text-align: left;
                        letter-spacing: 0px;
                        color: $black-color;
                        font-weight: 400;
                        font-size: 13px;
                        margin-bottom: 4px;
                    }

                    .form-control {
                        background-color: #f5f5f5;
                        border-radius: $border-radius;
                        letter-spacing: 0.13px;
                        color: #201f1f;
                        border: $border-none;
                        font-size: 12px;
                        font-weight: $font-weight-m;
                        padding: 9.34px;

                        &::placeholder {
                            font-weight: 400 !important;
                        }

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }

                        &select {
                            filter: saturate(0.5);
                        }

                        &.css-b62m3t-container {
                            padding: 0;

                            .css-13cymwt-control {
                                background: none;
                                border: none;
                            }

                            .css-t3ipsp-control {
                                border: 0 !important;
                                box-shadow: none !important;
                                background-color: #f5f5f5;

                                &:hover {
                                    border-color: transparent !important;
                                }
                            }

                            .css-1u9des2-indicatorSeparator {
                                display: none;
                            }

                            .css-1xc3v61-indicatorContainer,
                            .css-15lsz6c-indicatorContainer {
                                padding: 0;
                                padding-right: 8px !important;
                            }

                            svg.css-tj5bde-Svg {
                                fill: #636f83;
                            }

                            .css-1jqq78o-placeholder {
                                font-weight: normal;
                            }
                        }
                    }

                    .form-check {
                        margin: 8px 0;

                        input {
                            border-color: $red-color;

                            &:checked {
                                background-color: $red-color;
                                border-color: $red-color;
                            }

                            &:focus {
                                box-shadow: none;
                                outline: none;
                                border-color: $red-color;
                            }
                        }

                        label {
                            text-align: left;
                            letter-spacing: 0px;
                            color: $black-color;
                            font-weight: $font-weight-m;
                            font-size: 13px;
                        }
                    }
                }

                .editable {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .form-control {
                        background: #fff;
                        border-radius: $border-radius;
                        letter-spacing: 0.13px;
                        color: #201f1f;
                        border: 1px solid #d4d4d4;
                        appearance: auto;
                        font-size: 12px;
                        font-weight: 500;
                        width: 100%;
                        padding: 5px;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    i {
                        color: #a3a3a3;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }

                .note-item {
                    margin: 0;
                    padding: 10px 10px 10px;
                    border-bottom: 1px solid #d4d4d4;

                    .note-icon {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        margin-right: 15px;

                        svg {
                            color: #ff3d00;
                        }

                        img {
                            height: 32px;
                        }
                    }

                    .description {
                        width: 58%;

                        .title {
                            letter-spacing: 0px;
                            color: #120303;
                            text-transform: capitalize;
                            opacity: 1;
                            font-size: 13px;
                            font-weight: $font-weight-m;
                            text-align: left;
                            margin-bottom: 4px;
                            white-space: initial;
                        }

                        .designation {
                            letter-spacing: 0px;
                            color: #898686;
                            text-transform: capitalize;
                            opacity: 1;
                            font-size: 11px;
                            font-weight: 500;
                            text-align: left;
                            margin-bottom: 8px;
                            white-space: pre-line;
                        }
                    }

                    .address {
                        letter-spacing: 0px;
                        color: #120303;
                        text-transform: math-auto;
                        opacity: 1;
                        font-size: 12px;
                        font-weight: 500;
                        margin-bottom: 8px;
                        white-space: initial;
                        position: relative;
                        display: flex;

                        &.pre-line {
                            line-height: 16px;
                        }
                    }

                    .date {
                        letter-spacing: 0px;
                        color: #7c7c7c;
                        text-transform: uppercase;
                        opacity: 1;
                        font-size: 10px;
                        font-weight: 500;
                        margin-bottom: 0;
                        white-space: nowrap;
                        width: 23%;
                    }

                    .date-rfi {
                        .conversation-rfi-label {
                            border-radius: 4px;
                            color: darkblue;
                            font-size: 10px;
                            text-transform: capitalize;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            justify-content: start;
                            height: 24px;
                            margin-bottom: 0;

                            i {
                                font-size: 10px !important;
                                margin-right: 4px;
                            }
                        }
                    }

                    .uploaded-file {
                        .download-img {
                            right: 6px !important;
                        }
                    }

                    .link {
                        letter-spacing: 0px;
                        color: #428dc9;
                        font-size: $font-size;
                        font-weight: 400;
                        text-decoration: none;
                        cursor: pointer;

                        img {
                            height: 18px;
                            margin-right: 8px;
                        }
                    }

                    .edited-text {
                        color: gray;
                        font-weight: normal;
                        font-size: 10px;
                        margin-top: 10px;
                        margin-left: 10px;
                    }

                    &.showedit-item {
                        background-color: #f8f8f8;
                        border: 1px solid #d4d4d4;
                        border-radius: 4px;
                    }

                    .delete-icon {
                        display: flex;
                        align-items: center;
                        padding: 0 10px;
                        cursor: pointer;

                        i {
                            color: #9e9e9e;
                            font-size: 13px;
                        }
                    }
                }
            }

            .modal-footer {
                padding-left: 0;
                padding-right: 0;
                border: 0;

                .reset-btn {
                    background: #fff2f2;
                    border-radius: 4px;
                    letter-spacing: 0.13px;
                    color: $button-color;
                    font-size: $font-size;
                    font-weight: 500;
                    text-align: center;
                    width: 46.5%;
                    border: $border-none;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }

                .send-btn {
                    background: $red-color;
                    border-radius: 4px;
                    letter-spacing: 0.13px;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 500;
                    text-align: center;
                    width: 46.5%;
                    border: $border-none;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }
    }

    &.hidestatus {
        .modal-dialog {
            min-width: 300px !important;

            .modal-footer {
                flex-wrap: nowrap !important;
            }
        }
    }

    &.addedndum {
        .modal-body {
            .area-text .bottom-icons {
                background: #f5f5f5 !important;
                border-radius: 4px !important;
            }

            .file-section {
                display: $display-flex;
                justify-content: space-between;

                .form-label {
                    color: $black-color;
                    font-size: 13px;
                    font-weight: 400;
                }

                .file-list {
                    display: $display-flex;
                    justify-content: space-between;
                    align-items: $align-items-center;
                    background-color: #f5f5f5;
                    padding: 6px 10px;
                    width: 300px;
                    margin-bottom: 4px;
                    border-radius: $border-radius;

                    p {
                        color: $black-color;
                        margin-bottom: 0;
                        font-size: $font-size;
                        font-weight: 500;
                    }

                    .actions {
                        display: flex;
                        align-items: center;

                        span {
                            cursor: pointer;
                            margin: 0 4px;
                        }

                        img {
                            height: 12px;
                        }
                    }
                }

                .file-bottom-btn {
                    display: $display-flex;
                    align-items: end;
                    justify-content: end;
                }
            }

            .image-input {
                background: #fff;
                border: 1px dashed #a7a7a7;
                border-radius: 4px;
                text-align: center;
                width: 450px;

                input {
                    display: none;
                }

                label {
                    align-items: $align-items-center;
                    color: #878080;
                    cursor: pointer;
                    display: $display-flex;
                    flex-direction: column;
                    font-size: 13px;
                    font-weight: 400;
                    height: 80px;
                    justify-content: center;

                    span {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    &.contract {
                        height: 35px !important;
                    }
                }
            }

            table {
                margin: 15px 0;
                border-collapse: separate;
                border-spacing: 0 4px;

                thead {
                    th {
                        color: $black-color;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 0 0.5rem;
                    }
                }

                tbody {
                    tr {
                        margin: 5px 0;

                        td {
                            background: #f5f5f5;
                            color: #000035;
                            font-size: 13px;
                            font-weight: 500;
                            box-shadow: none;
                            border-bottom: 0;

                            &:first-child {
                                border-radius: 8px 0 0 8px;
                            }

                            &:last-child {
                                border-radius: 0px 8px 8px 0px;
                            }

                            button {
                                background-color: transparent;
                                padding: 0;
                                border: none;
                                margin: 0 5px;

                                img {
                                    height: 15px;
                                }

                                i {
                                    color: #9e9e9e;
                                    font-size: 15px;
                                }

                                a {
                                    color: inherit;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.uploaded-file {
    display: $display-flex;
    align-items: flex-start;
    padding: 8px 10px;
    border: 1px solid #b2abab;
    border-radius: 4px;
    width: 100% !important;
    position: relative;
    cursor: default;

    .file-img {
        height: 22px;
        object-fit: contain;
        margin-top: 3px;
    }

    .download-img {
        height: 15px;
        cursor: pointer;

        &.copy {
            height: 12px !important;
        }
    }

    i {
        position: absolute;
        right: -5px;
        bottom: 30px;
        color: #fff;
        background-color: #000;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        display: none;
        cursor: pointer;
    }

    &:hover {
        i {
            display: flex;
        }
    }

    .file-name {
        margin: 0 8px;
        display: grid;

        a {
            font-size: 12px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    h5 {
        letter-spacing: 0px;
        color: #120303;
        opacity: 1;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
        margin-top: 2px;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: auto;
    }

    p {
        letter-spacing: 0px;
        color: #716b6b;
        opacity: 1;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 0;
    }
}

.file-actions {
    letter-spacing: 0px;
    color: #878080;
    opacity: 1;
    font-size: 11px;
    font-weight: 400;
    cursor: pointer;

    span {
        color: #878080;
        font-size: 10px;
        margin: 0 5px;
        cursor: default;
    }
}

.area-text {
    width: 100%;
    position: relative;
    margin-bottom: 2px;

    textarea {
        background-color: #faf8f8 !important;
        border-radius: 4px;
        border: $border-none;
        letter-spacing: 0px;
        color: #120303;
        opacity: 1;
        font-size: 12px;
        font-weight: 500;
        text-align: left;
        line-height: 16px;
        text-transform: math-auto;
        height: 120px;
        overflow: auto;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            font-weight: 400 !important;
            font-size: 12px;
        }
    }

    .bottom-icons {
        background: #e8e5e5;
        border-radius: 0 0 4px 4px;
        padding: 4px 12px;
        position: relative;

        &:has(.uploaded-span) {
            margin-top: 60px;
        }

        .up-btn {
            background: transparent;
            border: $border-none;
            padding-top: 3px !important;
            padding-bottom: 3px !important;
            padding-left: 6px;
            padding-right: 6px;
            border-radius: 4px;
            margin-right: 8px;

            &.link {
                img {
                    height: 12px;
                }

                i {
                    transform: rotate(135deg);
                }
            }

            &.attach {
                i {
                    transform: rotate(45deg);
                }
            }

            img {
                height: 18px;
            }

            i {
                color: #aba9a9;
                font-size: 18px;
            }

            &:hover {
                i {
                    color: #4d4242;
                }
            }

            &.active {
                i {
                    color: #4d4242;
                }
            }
        }

        .send-btn {
            width: auto !important;
            position: absolute;
            right: 10px;
            padding: 7px 13px;
            border-radius: 4px !important;
            top: 6.3px;

            img {
                margin-left: 10px;
                height: 12px;
            }
        }

        .file-section {
            position: absolute;
            bottom: 41px;
            width: 100%;
            background: #faf8f8;
            right: 0px;
            padding: 5px;

            .image-input {
                input {
                    opacity: 0;
                    position: fixed;
                    bottom: 12px;
                    width: 34px;
                    font-size: 0;
                    height: 10px;
                    margin-left: 14px;
                    padding: 4px;
                    margin-bottom: 6px;
                }

                &:hover {
                    .up-btn:not(.link) {
                        i {
                            color: #4d4242;
                        }
                    }
                }

                label {
                    align-items: $align-items-center;
                    color: #878080;
                    cursor: pointer;
                    display: block;
                    display: $display-flex;
                    flex-direction: column;
                    font-size: 13px;
                    font-weight: 400;
                    height: 20px;
                    justify-content: center;

                    .uploaded-span {
                        position: relative;

                        i {
                            position: absolute;
                            right: -16px;
                            bottom: 20px;
                            color: #fff;
                            background-color: #000;
                            border-radius: 50%;
                            width: 15px;
                            height: 15px;
                            align-items: center;
                            justify-content: center;
                            font-size: 10px;
                            display: none;
                        }

                        img {
                            height: 20px;
                            margin-right: 4px;
                        }

                        p {
                            overflow: hidden;
                            width: 86px;
                            text-overflow: ellipsis;
                            display: block !important;
                            white-space: nowrap;
                            color: #4d4242;
                            font-size: 12px;
                            font-weight: 400;
                            margin-bottom: 0;
                            text-align: left;
                        }
                    }
                }

                .image-button {
                    &:has(.uploaded-span) {
                        border: 1px solid #c2bfbf !important;
                        background: #fff;
                        border: 1px dashed #a7a7a7;
                        border-radius: 4px;
                        text-align: center;
                        width: 30%;
                        padding: 1rem 0;
                        margin-bottom: 4px;
                        margin-right: 4px;

                        &:hover {
                            .uploaded-span {
                                i {
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }

            .more-files {
                letter-spacing: 0px;
                color: $black-color;
                opacity: 1;
                font-size: 11px;
                font-weight: 500;
                margin-bottom: 0;
                white-space: nowrap;
                cursor: pointer;
            }
        }

        .addlink-modal {
            position: absolute;
            margin-top: 0;
            margin-bottom: 0;
            max-width: 30%;
            bottom: 48px;
            height: 220px;
            min-width: 280px;
            background-color: #fff;
            box-shadow: 0px 3px 6px rgba(3, 7, 87, 0.2235294118);
            border-radius: 4px;
            border: 0;
            padding: 20px;

            .input-text {
                margin-bottom: 0.8rem;
            }

            input {
                font-size: 12px;
                font-weight: 500;
                border-radius: 4px;

                &::placeholder {
                    color: #878080;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border-color: #e8e5e5 !important;
                }
            }

            label {
                text-align: left;
                letter-spacing: 0px;
                color: #0e0000;
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 4px;
            }

            .save-btn {
                background: #c3c0c0;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #fff;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                border: none;
                width: 100%;
                height: 30px;
            }

            .cancel-btn {
                background: #e6e4e4;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #c0bdbd;
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                border: none;
                width: 100%;
                height: 30px;
            }
        }
    }
}

.tooltip {
    .tooltip-inner {
        font-size: 10px;
        border-radius: 4px;
        background-color: #120303;
        color: #ffffff;
    }
}

.sub-breadcrumb {
    margin-top: 10px;
    margin-bottom: 10px;

    img {
        height: 14px;
        margin-right: 6px;
    }

    a {
        letter-spacing: 0px;
        color: #8b0000;
        opacity: 1;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        filter: opacity(0.4);
        position: relative;
        top: -2px;
    }

    span:not(.arrow) {
        letter-spacing: 0px;
        color: $button-color;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 400;
        cursor: default;
    }

    .arrow.right {
        background: #e8cccc;
        border-radius: 4px;
        padding: 5px 5px;
        margin-right: 8px;

        svg {
            transform: rotate(180deg);
        }
    }
}

.mt-negative {
    margin-top: -8px !important;
}

.form-error {
    font-size: 12px !important;
    color: red !important;
    font-weight: normal !important;
    margin: 2px 0;
}

.delete-modal {
    .modal-dialog {
        width: 310px;
    }

    .modal-content {
        width: auto !important;
    }

    .modal-body {
        h2 {
            letter-spacing: 0.29px;
            color: $black-color;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 5px;
            text-align: center;
        }

        p {
            letter-spacing: 0.29px;
            color: $black-color;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px;
            text-align: center;
        }

        img {
            height: 54px;
            margin-bottom: 20px;
        }
    }

    .delete-body {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .reset-btn {
            background: #fff2f2;
            border-radius: 8px;
            letter-spacing: 0.13px;
            color: $button-color;
            font-size: $font-size;
            font-weight: 500;
            text-align: center;
            width: 100px;
            border: $border-none;
            padding: 6.67px;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }

        .send-btn {
            background: $red-color;
            border-radius: 8px;
            letter-spacing: 0.13px;
            color: #ffffff;
            font-size: $font-size;
            font-weight: 500;
            text-align: center;
            width: 100px;
            border: $border-none;
            padding: 6.67px;

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

    &.holiday {
        .modal-dialog {
            min-width: 78% !important;
            justify-content: center !important;

            .modal-content {
                width: 78% !important;
            }

            .left-icon {
                background-color: $red-color;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: calc(0.5rem - (1px));
                border-bottom-left-radius: calc(0.5rem - (1px));
                width: 100%;

                img {
                    height: 20%;
                }
            }

            .modal-header {
                color: $red-color;
                font-size: 18px;
                font-weight: $font-weight-m;
                padding-left: 0;
                padding-bottom: 5px !important;

                .btn-close {
                    color: $black-color !important;
                    opacity: 1 !important;
                }
            }

            .modal-body {
                padding-left: 0;

                .delete-body {
                    align-items: baseline !important;
                }

                h2 {
                    text-align: left;
                    font-size: 15px !important;
                }

                p {
                    text-align: justify;
                    white-space: pre-line;

                    span {
                        font-weight: $font-weight-m;
                    }
                }
            }
        }
    }

    &.sm {
        background-color: #00001547;

        .modal-dialog {
            margin-right: 4% !important;
            margin-top: 10%;
        }
    }

    &.md {

        .modal-dialog,
        .modal-content {
            width: 370px !important;
        }

        .modal-header {
            letter-spacing: 0.29px;
            color: #0e0000;
            font-size: 18px;
            font-weight: 700;
            text-align: center;
            justify-content: $align-items-center;

            .btn-close {
                font-size: 12px;
                opacity: 1 !important;
                margin-left: 0;
                position: absolute;
                right: 15px;

                &:focus {
                    outline: $border-none;
                    box-shadow: $border-none;
                }
            }
        }

        .modal-body {
            .delete-body {
                align-items: baseline;
            }

            .action-btn {
                display: $display-flex;
                align-items: $align-items-center;
                justify-content: $align-items-center;
                width: 100%;
            }

            .input-text {
                width: 100%;
            }

            textarea {
                background-color: #f5f5f5;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #201f1f;
                border: none;
                font-size: 12px;
                font-weight: 600;
                padding: 9.34px;

                &::placeholder {
                    font-weight: 400 !important;
                    font-size: 12px;
                }

                &:focus {
                    outline: $border-none;
                    border: $border-none;
                    box-shadow: $border-none;
                }
            }

            .form-label {
                text-align: left;
                letter-spacing: 0px;
                color: #0e0000;
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 4px;
            }
        }
    }
}

.status-modal {
    .modal-dialog {
        width: 360px !important;
    }
}

.feedback-modal {
    .modal-dialog {
        width: 420px;
        position: fixed;
        right: 0;
        bottom: 0;
        margin-top: 0;
        margin-bottom: 0;

        .modal-content {
            background-color: $red-color;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 4px 0px 0px 4px;
            padding: 10px 24px 10px 32px;
        }
    }

    .modal-header {
        display: flex;
        align-items: baseline;
        padding: 1rem 0;

        h2 {
            letter-spacing: 0.29px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            text-align: left;
        }

        .close-btn {
            padding: 0;
            position: absolute;
            right: 6px;
            top: 4px;

            i {
                color: #fff;
                font-size: 16px;
                font-weight: normal;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .btn-close {
            display: none;
        }
    }

    .modal-body {
        padding: 1rem 0;

        p {
            letter-spacing: 0.29px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 5px;
            text-align: left;
            line-height: 24px;
            text-transform: lowercase;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        img {
            height: 54px;
            margin-bottom: 20px;
        }
    }

    .text-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .action-btn {
            width: 100%;
            display: $display-flex;
            align-items: $align-items-center;
            justify-content: $align-items-center;

            a {
                text-decoration: none;
                color: inherit;
                width: 34%;
            }
        }

        .reset-btn {
            background: #ffffff;
            border-radius: 4px;
            letter-spacing: 0.13px;
            color: $button-color;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            width: 100%;
            height: 36px;
            border: $border-none;
            padding: 8.67px;

            a {
                color: inherit;
                text-decoration: none;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }

            &:hover {
                background: #ffe5e5;
            }
        }

        .send-btn {
            background: $red-color;
            border-radius: 4px;
            letter-spacing: 0.13px;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            width: 34%;
            height: 36px;
            border: 1px solid #ffffff;
            padding: 8.67px;

            &:focus {
                box-shadow: none;
                outline: none;
            }

            &:hover {
                background: #810101;
            }
        }
    }
}

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;

    .n-found {
        img {
            height: 50px;
            margin-right: 10px;
        }

        h2 {
            font-size: 32px;
            font-weight: $font-weight-m;
            color: $black-color;
            text-align: left;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            text-align: left;

            a {
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
    }
}

.set-position {
    position: relative;

    .button-position {
        position: absolute;
        bottom: 8px;
        right: 5%;
    }
}

.singleSelection .dropdown-content input[type="checkbox"] {
    display: none;
}

.singleSelection .dropdown-content li:has([text="Select All"]) {
    display: none;
}

.skelton-container {
    padding: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
    border-radius: 0.375rem;
    border: none;
    line-height: 1;

    .avatar {
        border-radius: 8px;
        padding: 3px;
        border: none;
        width: 30px;
        height: 30px;
    }

    .full-div {
        span[aria-live="polite"] {
            width: 100%;
        }
    }

    span[aria-live="polite"] {
        width: 100%;
        margin-right: 10px;

        &:last-child {
            margin-right: 0 !important;
        }

        &:has(.avatar) {
            width: auto !important;
        }

        &:has(.w-5) {
            width: 5%;
        }

        &:has(.w-10) {
            width: 10%;
        }

        &:has(.w-15) {
            width: 15%;
        }

        &:has(.w-20) {
            width: 20%;
        }

        &:has(.w-30) {
            width: 30%;
        }

        &:has(.w-40) {
            width: 40%;
        }

        &:has(.w-55) {
            width: 50%;
        }

        &:has(.w-60) {
            width: 60%;
        }

        &:has(.w-70) {
            width: 70%;
        }

        &:has(.w-80) {
            width: 80%;
        }

        &:has(.w-90) {
            width: 90%;
        }

        // &:last-child{
        //     width: 100% !important;
        // }
    }
}

.Toastify__toast-container {
    width: 340px !important;
    top: 60px !important;
    right: 0 !important;

    .Toastify__toast {
        // background: transparent linear-gradient(180deg, #12895C 0%, #04623E 100%) !important;
        margin-bottom: 0;

        .Toastify__toast-body {
            color: #ffffff;
            text-transform: capitalize;
        }

        .Toastify__toast-icon {
            svg {
                fill: #fff;
            }
        }

        // .Toastify__progress-bar--wrp{
        //     display: none;
        // }
        .Toastify__close-button {
            opacity: 1;

            svg {
                fill: #fff;
            }
        }

        .text-body {
            h2 {
                color: #fff;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 4px;
            }

            p {
                color: #fff;
                font-size: 13px;
                font-weight: 400;
                margin-bottom: 4px;
            }
        }
    }
}

.loader-box {
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    opacity: 0.6;
    z-index: 0;
    position: fixed;
    display: $display-flex;
    align-items: $align-items-center;
    justify-content: $align-items-center;

    .loader {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        border: 3px solid;
        border-color: #fff #fff transparent transparent;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        opacity: 1;
    }

    .loader::after,
    .loader::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent $red-color $red-color;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }

    .loader::before {
        width: 32px;
        height: 32px;
        border-color: #fff #fff transparent transparent;
        animation: rotation 1.5s linear infinite;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes rotationBack {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(-360deg);
        }
    }
}

.viewall-notify {
    .note-item {
        margin: 0;
        background: #fcfcfc;
        border-radius: 4px;
        padding: 14px 10px 15px;
        margin-bottom: 5px;

        &.active {
            border-left: 4px solid #ff7979;
        }

        &:hover {
            background: #fdfafa;

            .description {
                .title {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .note-icon {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 15px;

            svg {
                color: #ff3d00;
            }

            img {
                width: 1.8rem;
                height: 1.8rem;
            }
        }

        .description {
            .title {
                letter-spacing: 0px;
                color: #120303;
                text-transform: capitalize;
                opacity: 1;
                font-size: 13px;
                font-weight: 600;
                text-align: left;
                margin-bottom: 4px;
                white-space: initial;
            }

            .designation {
                letter-spacing: 0px;
                color: $button-color;
                text-transform: capitalize;
                opacity: 1;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                margin-bottom: 8px;
                white-space: initial;
            }
        }

        .address {
            letter-spacing: 0px;
            color: #120303;
            text-transform: capitalize;
            opacity: 1;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 4px;
            white-space: initial;
        }

        .date {
            letter-spacing: 0px;
            color: #7c7c7c;
            float: right;
            text-transform: uppercase;
            opacity: 1;
            font-size: 10px;
            font-weight: 500;
            margin-bottom: 4px;
            white-space: nowrap;
        }

        .delete-icon {
            float: right;
            background-color: #f9f2f2;
            padding: 4px 4px;
            height: 22px;
            width: 22px;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                color: #747c8a;
                width: 0.8rem;
                height: 0.8rem;
            }

            &:hover {
                svg {
                    color: #8b0000;
                }
            }
        }
    }

    .dropdown-divider {
        margin: 0;
        border-color: #ebf0e9;
    }

    .notify-block {
        overflow: auto;
        height: 94vh;
    }
}

.form-label.custom {
    text-align: left;
    letter-spacing: 0px;
    color: #0e0000;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 4px;
    white-space: nowrap;
}

.stepper-progress {
    border-radius: 4px;
    width: 98%;
    margin: 10px;
    padding-bottom: 0;
    position: absolute;
    height: auto;
    background: #f7fbff;
    box-shadow: 0px 1px 5px rgb(0 0 0 / 38%);
    z-index: 9;

    h2 {
        letter-spacing: 0px;
        color: #120303;
        opacity: 1;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 2px;
        line-height: 16px;
        text-transform: math-auto;
        white-space: nowrap;
    }

    p {
        letter-spacing: 0px;
        color: #5e3737;
        opacity: 1;
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 0;
        white-space: nowrap;
    }

    .detail {
        margin: 10px 15px 0;
        display: flex;

        .v-title {
            display: flex;
            margin-right: 30px;

            p {
                margin-right: 5px;
            }
        }
    }

    .close-btn {
        float: right;
        background: $red-color;
        width: 20px;
        height: 20px;
        padding: 10px;
        margin: 1px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        color: #fff;
        border-radius: 50%;
        font-size: 13px;

        &:hover {
            background-color: #b14747fc !important;
            color: #fff;
        }
    }

    button {
        background-color: #a2cffe;

        &[disabled] {
            border: 3px solid #e3e3e3;
            background-color: #fff;

            span {
                background-color: #e3e3e3;
                margin: 5px;
                border-radius: 50%;
                padding: 1px 4px;
                width: 20px;
                min-width: 20px;
                height: 20px;
            }
        }

        &:hover {
            background-color: transparent !important;
        }

        span {
            font-size: 0;
        }

        &.completed {
            border: 3px solid #008c76;
            background-color: #fff;

            span {
                background-color: #008c76;
                margin: 5px;
                border-radius: 50%;
                padding: 1px 4px;

                &:before {
                    content: "\f00c";
                    font-family: "FontAwesome";
                    font-size: 12px;
                }
            }
        }

        &.active {
            border: 3px solid #0468c8;
            background-color: #fff;

            span {
                background-color: #0468c8;
                margin: 5px;
                border-radius: 50%;
                padding: 1px 4px;
                width: 20px;
                min-width: 20px;
                height: 20px;
            }
        }
    }

    #RFS-Connector {
        border-color: #e3e3e3;
        border-width: 3px;
        border-radius: 4px;

        &.completed {
            border-color: #008c76;
        }

        &.active {
            border-color: #0468c8;
        }
    }
}

.stepper-progress {
    overflow: auto;
}

// .loading-anim{
//     position: fixed;
//     background-color: #00000075;
//     height: 100vh;
//     width: 100vw;
//     top: 0;
//     z-index: 9999;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     left: 0;
// }
// scrollbar styles
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #dadada;
}

::-webkit-scrollbar-thumb {
    background-color: #818181;
    border-radius: 6px;
}

.no-data {
    text-align: center;
    padding: 15px;
    letter-spacing: 0px;
    color: #888181;
    opacity: 1;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pre-line {
    white-space: pre-line !important;
}

.text-transform-normal {
    text-transform: inherit !important;
}

.header-color {
    // background-color: #e8e8e873  !important;
    background-color: rgba(232, 232, 232, 0.4509803922) !important;
    box-shadow: 0px 0px 3px 1px #00000014;
    border-radius: 0 !important;
}

.chat-by-voice {
    position: absolute;
    right: 7px;
    z-index: 99;
    top: 0;
    cursor: pointer;

    svg {
        width: 1rem;
        height: 1rem;
        fill: #aba9a9;
    }
}

.h-99 {
    height: 98.5%;
}

.loaders {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    .la-line-spin-fade-rotating,
    .la-line-spin-fade-rotating>div {
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .la-line-spin-fade-rotating {
        display: block;
        font-size: 0;
        color: #fff;
    }

    .la-line-spin-fade-rotating.la-dark {
        color: #333;
    }

    .la-line-spin-fade-rotating>div {
        display: inline-block;
        float: none;
        background-color: #a1a1a3;
        border: 0 solid #a1a1a3;
    }

    .la-line-spin-fade-rotating {
        width: 32px;
        height: 32px;
        -webkit-animation: ball-spin-fade-rotating-rotate 6s infinite linear;
        -moz-animation: ball-spin-fade-rotating-rotate 6s infinite linear;
        -o-animation: ball-spin-fade-rotating-rotate 6s infinite linear;
        animation: ball-spin-fade-rotating-rotate 6s infinite linear;
    }

    .la-line-spin-fade-rotating>div {
        position: absolute;
        width: 2px;
        height: 10px;
        margin: 2px;
        margin-top: -5px;
        margin-left: -1px;
        border-radius: 0;
        -webkit-animation: line-spin-fade-rotating 1s infinite ease-in-out;
        -moz-animation: line-spin-fade-rotating 1s infinite ease-in-out;
        -o-animation: line-spin-fade-rotating 1s infinite ease-in-out;
        animation: line-spin-fade-rotating 1s infinite ease-in-out;
    }

    .la-line-spin-fade-rotating>div:nth-child(1) {
        top: 15%;
        left: 50%;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-animation-delay: -1.125s;
        -moz-animation-delay: -1.125s;
        -o-animation-delay: -1.125s;
        animation-delay: -1.125s;
    }

    .la-line-spin-fade-rotating>div:nth-child(2) {
        top: 25.2512626585%;
        left: 74.7487373415%;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-animation-delay: -1.25s;
        -moz-animation-delay: -1.25s;
        -o-animation-delay: -1.25s;
        animation-delay: -1.25s;
    }

    .la-line-spin-fade-rotating>div:nth-child(3) {
        top: 50%;
        left: 85%;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-animation-delay: -1.375s;
        -moz-animation-delay: -1.375s;
        -o-animation-delay: -1.375s;
        animation-delay: -1.375s;
    }

    .la-line-spin-fade-rotating>div:nth-child(4) {
        top: 74.7487373415%;
        left: 74.7487373415%;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        -webkit-animation-delay: -1.5s;
        -moz-animation-delay: -1.5s;
        -o-animation-delay: -1.5s;
        animation-delay: -1.5s;
    }

    .la-line-spin-fade-rotating>div:nth-child(5) {
        top: 84.9999999974%;
        left: 50.0000000004%;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-animation-delay: -1.625s;
        -moz-animation-delay: -1.625s;
        -o-animation-delay: -1.625s;
        animation-delay: -1.625s;
    }

    .la-line-spin-fade-rotating>div:nth-child(6) {
        top: 74.7487369862%;
        left: 25.2512627193%;
        -webkit-transform: rotate(225deg);
        -moz-transform: rotate(225deg);
        -ms-transform: rotate(225deg);
        -o-transform: rotate(225deg);
        transform: rotate(225deg);
        -webkit-animation-delay: -1.75s;
        -moz-animation-delay: -1.75s;
        -o-animation-delay: -1.75s;
        animation-delay: -1.75s;
    }

    .la-line-spin-fade-rotating>div:nth-child(7) {
        top: 49.9999806189%;
        left: 15.0000039834%;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
        -webkit-animation-delay: -1.875s;
        -moz-animation-delay: -1.875s;
        -o-animation-delay: -1.875s;
        animation-delay: -1.875s;
    }

    .la-line-spin-fade-rotating>div:nth-child(8) {
        top: 25.2506949798%;
        left: 25.2513989292%;
        -webkit-transform: rotate(315deg);
        -moz-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
        -o-transform: rotate(315deg);
        transform: rotate(315deg);
        -webkit-animation-delay: -2s;
        -moz-animation-delay: -2s;
        -o-animation-delay: -2s;
        animation-delay: -2s;
    }

    .la-line-spin-fade-rotating.la-2x {
        width: 64px;
        height: 64px;
    }

    .la-line-spin-fade-rotating.la-2x>div {
        width: 4px;
        height: 20px;
        margin-top: -10px;
        margin-left: -2px;
    }

    /*
 * Animations
 */

    @-webkit-keyframes line-spin-fade-rotating {
        50% {
            opacity: 0.3;
        }

        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes line-spin-fade-rotating {
        50% {
            opacity: 0.3;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes line-spin-fade-rotating {
        50% {
            opacity: 0.3;
        }

        100% {
            opacity: 1;
        }
    }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: $button-color;

    &:before {
        content: "";
        position: fixed;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        border: 6px solid $button-color;
        border-top-color: #fff;
        border-bottom-color: #fff;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        animation: animate-preloader 1s linear infinite;
    }
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.chart-tooltip {
    h2 {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 0;
    }

    i {
        font-size: 8px;
        margin-right: 4px;

        &.Used {
            color: #ff9392;

            &::before {
                background-color: #ff9392;
                box-shadow: 0px 0px 0px 2px #ffffff;
            }
        }

        &.Free {
            color: #ffdddd;

            &::before {
                background-color: #ffdddd;
                box-shadow: 0px 0px 0px 2px #ffffff;
            }
        }
    }

    p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
    }
}

.modal-backdrop {
    width: 100%;
    height: 100%;
}

.back-to-new {
    position: fixed;
    // visibility: hidden;
    // opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: $red-color;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    text-decoration: none;
    transition: all ease;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

    .new-text {
        display: none;
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }

    &:hover {
        width: 130px;

        .new-text {
            display: flex;
            font-size: 13px;
            color: #fff;
            font-weight: 400;
        }

        i {
            font-size: 14px !important;
            margin-right: 0.5rem;
        }
    }

    i {
        font-size: 20px;
        color: #fff;
        line-height: 0;
    }
}

.manage-storage-datepicker {
    .react-datepicker-wrapper {
        width: 100%;

        input {
            font-size: 12px;
            font-weight: 500;
            color: #201f1f;
            background-color: #f5f5f5;
            border-radius: 4px;
            border: none;
            width: 100%;
            padding-left: 20px;
            cursor: pointer;
            height: 34px;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.date-to {
                padding: 5.5px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.date-from {
                padding-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        svg {
            position: absolute;
            left: 5px;
            top: 9px;
            font-size: 13px;
        }
    }

    .react-datepicker__day--keyboard-selected:hover {
        color: #fff;
        font-weight: normal !important;
    }

    &.multi-calendar {
        .react-datepicker-wrapper {
            width: 100%;

            input {
                height: 30px;
                background-color: #fff;
            }
        }

        .react-datepicker-popper {
            z-index: 9999;
        }
    }

    .connected-span {
        background-color: #fff;
        padding: 2.5px;
    }

    .show-calendar {
        .react-datepicker-wrapper {
            position: absolute;
            z-index: 99;
            width: 128px;

            input {
                font-size: 11px;
                padding-left: 16px;
            }
        }

        .react-datepicker {
            .react-datepicker__navigation {
                width: 10px !important;
                background-color: transparent !important;
                padding-left: 0 !important;
            }
        }

        img {
            z-index: 999;
        }
    }
}

.dashboard {
    .invoice-summary-table-area {
        margin-top: 34px;

        .table-responsive {
            table {
                tbody {
                    tr {
                        th {
                            font-weight: 600;
                        }

                        .fw-500 {
                            font-weight: 500;
                        }

                        th,
                        td {
                            font-size: 12px;
                            vertical-align: middle;
                            text-align: center;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        .header-color-bg {
                            background-color: #ffe0e0;
                        }

                        .gray-color {
                            background-color: #e5e5e5;
                        }
                    }
                }

                tfoot {
                    tr {
                        th {
                            font-size: 12px;
                            font-weight: 500;
                            background-color: #e5e5e5;
                            vertical-align: middle;
                            text-align: center;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        .header-color-bg {
                            background-color: #ffe0e0;
                        }

                        .gray-color {
                            background-color: #e5e5e5;
                        }
                    }
                }
            }
        }
    }
}

.flex-basis {
    flex-basis: 30%;
    flex-shrink: 0;
}

.reset-btn {
    background: #ffe5e5 !important;
    border-radius: 4px;
    letter-spacing: 0.13px;
    color: #530000 !important;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 110px;
    border: none;
    padding: 8.67px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: inherit;
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.send-btn {
    background: #8b0000 !important;
    border-radius: 4px;
    letter-spacing: 0.13px;
    color: #ffffff !important;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    width: 110px;
    border: none;
    padding: 8.67px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.clear-btn {
    background-color: #f5f5f5;
    border-radius: 4px;
    letter-spacing: 0.13px;
    color: #201f1f;
    border: none;
    padding-top: 5px;
    height: 34px;
    margin-top: 25px;

    i {
        color: #919191;
    }

    &:hover {
        background-color: #e0e0e0 !important;
        color: $black-color !important;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }
}

.date-range-picker {
    select {
        border-radius: 4px;
        letter-spacing: 0.13px;
        color: #425b50;
        opacity: 1;
        font-size: 12px;
        font-weight: 500;
        border: $border-none;
        padding: 0 8px;
        font-family: "Sarabun", sans-serif;
        background-color: #fff !important;
        height: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &.cal-select {
        select {
            width: 145px !important;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;

        input {
            border: none;
            border-radius: 4px;
            width: 100%;
            font-size: 10.5px;
            font-weight: 500;
            padding-left: 25px;
            background-color: #f5f5f5;
            height: 34px;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &.date-to {
                padding: 5.5px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.date-from {
                padding-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        svg {
            position: absolute;
            left: 5px;
            top: 10px;
            font-size: 13px;
        }
    }

    .react-datepicker__day--keyboard-selected:hover {
        color: #fff;
        font-weight: normal !important;
    }

    &.multi-calendar {
        .react-datepicker-wrapper {
            width: 100%;

            input {
                height: 30px;
                background-color: #f5f5f5;
            }
        }

        .react-datepicker-popper {
            z-index: 9999;
        }
    }

    .connected-span {
        background-color: #fff;
        padding: 2.5px;
    }

    .show-calendar {
        .react-datepicker-wrapper {
            position: absolute;
            z-index: 99;
            width: 128px;

            input {
                font-size: 11px;
                padding-left: 16px;
            }
        }

        .react-datepicker {
            .react-datepicker__navigation {
                width: 10px !important;
                background-color: transparent !important;
                padding-left: 0 !important;
            }
        }

        img {
            z-index: 999;
        }
    }

    a {
        text-decoration: none;
    }
}

// dueDateChangeModal start
#dueDateChangeModal {
    .modal-body {
        .due-date-change-modal-body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                height: 54px;
                margin-bottom: 20px;
                object-fit: contain;
            }

            h2 {
                letter-spacing: 0.29px;
                color: $black-color;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 5px;
                text-align: center;
            }

            p {
                letter-spacing: 0.29px;
                color: $black-color;
                font-size: 14px;
                margin-bottom: 5px;
                text-align: center;
            }

            .form-label {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }

            .mandatory {
                color: rgb(255, 0, 0);
            }

            .form-control {
                background-color: #f5f5f5;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #201f1f;
                border: none;
                font-size: 12px;
                font-weight: 600;
                padding: 9.34px;
            }

            .reject-btn {
                background: #fff2f2;
                border-radius: 8px;
                letter-spacing: 0.13px;
                color: $button-color;
                font-size: $font-size;
                font-weight: 500;
                text-align: center;
                width: 100px;
                border: $border-none;
                padding: 6.67px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .accept-btn {
                background: $red-color;
                border-radius: 8px;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: $font-size;
                font-weight: 500;
                text-align: center;
                width: 100px;
                border: $border-none;
                padding: 6.67px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
}

// dueDateChangeModal end
.pagination {
    .page-link {
        color: #000000;
    }

    .active {
        .page-link {
            color: #fff;
            background-color: #8b0000 !important;
            border-color: #8b0000 !important;
        }
    }
}

.project-assigned-to {
    select {
        flex-grow: 1;
        flex-basis: 0;
    }

    .edit-icon {
        font-size: 14px;
        color: #003ed9;
        cursor: pointer;
    }

    .success {
        font-size: 14px;
        color: green;
        cursor: pointer;
    }

    .danger {
        font-size: 14px;
        color: red;
        cursor: pointer;
    }
}

// otherServiceRequestModal start
#otherServiceRequestModal {
    .modal-body {
        .other-service-change-modal-body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
                height: 54px;
                margin-bottom: 20px;
                object-fit: contain;
            }

            h2 {
                letter-spacing: 0.29px;
                color: $black-color;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 5px;
                text-align: center;
            }

            p {
                letter-spacing: 0.29px;
                color: $black-color;
                font-size: 14px;
                margin-bottom: 5px;
                text-align: center;
            }

            .form-label {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0;
            }

            .mandatory {
                color: rgb(255, 0, 0);
            }

            .form-control {
                background-color: #f5f5f5;
                border-radius: 4px;
                letter-spacing: 0.13px;
                color: #201f1f;
                border: none;
                font-size: 12px;
                font-weight: 600;
                padding: 9.34px;
            }

            .reject-btn {
                background: #fff2f2;
                border-radius: 8px;
                letter-spacing: 0.13px;
                color: $button-color;
                font-size: $font-size;
                font-weight: 500;
                text-align: center;
                width: 100px;
                border: $border-none;
                padding: 6.67px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .accept-btn {
                background: $red-color;
                border-radius: 8px;
                letter-spacing: 0.13px;
                color: #ffffff;
                font-size: $font-size;
                font-weight: 500;
                text-align: center;
                width: 100px;
                border: $border-none;
                padding: 6.67px;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
}

// otherServiceRequestModal end