html {
    &:not([dir="rtl"]) {
        .wrapper {
            padding-left: var(--cui-sidebar-occupy-start, 0);
        }
    }
}
.sidebar {
    background: #8b0000;
    border-radius: 10px;
    opacity: 1;
    // width: 210px;
    --cui-sidebar-width: 13rem !important;
    &.sidebar-fixed {
        &.sidebar-narrow-unfoldable {
            width: var(--cui-sidebar-narrow-width) !important;
            padding-bottom: 0 !important;
            .sidebar-brand {
                img {
                    height: 20px;
                }
            }
            .sidebar-nav {
                .nav-link {
                    padding: 5.8px;
                    padding-left: 60px !important;
                    background-position: 22px !important;
                    background-size: 16px !important;
                    width: 12px;
                    overflow: hidden;
                    padding-right: 0px;
                    &.active,
                    &:hover {
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav-item {
                    margin: 0 !important;
                }
                .nav-title {
                    padding: 0;
                }
            }
            &:hover {
                .nav-title {
                    height: 0 !important;
                    margin: 0;
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }
    &:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 13rem;
    }
    &.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
        --cui-sidebar-occupy-start: 4rem !important;
    }
    .sidebar-brand {
        background: #8b0000;
        box-shadow: 0px 3px 2px #0000000f;
        flex: 0 0 46px !important;
        border-radius: 10px;
        img {
            height: 30px;
        }
        .version {
            margin-bottom: 0;
            font-size: 12px;
            color: #f5e9e9;
            text-decoration: none;
            margin-top: 4px;
            line-height: 1;
            text-align: center;
        }
        a {
            margin: 0.5rem 0;
            text-decoration: none;
        }
    }
    .sidebar-nav {
        background: #8b0000;
        border-radius: 0px;
        box-shadow: 0px 3px 6px #0000000f;
        margin-top: 1px;
        .nav-item {
            margin: 3.34px;
            .nav-link {
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                font-size: 12px;
                font-weight: 500;
                text-align: left;
                padding: 2.5px;
                border-radius: 4px;
                background-size: 13px !important;
                background-position: 18px !important;
                padding-left: 40px;
                &[type="Dashboard"] {
                    background: url(../images/navbar/dashboard.png) left center / contain no-repeat;
                }
                &[type="Analytics"] {
                    background: url(../images/navbar/analytics.png) left center / contain no-repeat;
                }
                &[type="Manage Projects"] {
                    background: url(../images/navbar/manage-projects.png) left center / contain no-repeat;
                }
                &[type="Duedate Calendar"] {
                    background: url(../images/navbar/calendar.png) left center / contain no-repeat;
                }
                &[type="Invoices"] {
                    background: url(../images/navbar/invoices.png) left center / contain no-repeat;
                }
                &[type="Manage Storage"] {
                    background: url(../images/navbar/storage.png) left center / contain no-repeat;
                }
                &[type="Net Promoter Score"] {
                    background: url(../images/navbar/promoter-score.png) left center / contain no-repeat;
                }
                &[type="Manage Customers"] {
                    background: url(../images/navbar/customers.png) left center / contain no-repeat;
                }
                &[type="Manage Branches"] {
                    background: url(../images/navbar/branches.png) left center / contain no-repeat;
                }
                &[type="Manage Services"] {
                    background: url(../images/navbar/services.svg) left center / contain no-repeat;
                }
                &[type="Manage Added Services"] {
                    background: url(../images/navbar/added-services.svg) left center / contain no-repeat;
                }
                &[type="Manage Partners"] {
                    background: url(../images/navbar/partners.svg) left center / contain no-repeat;
                }
                &[type="Manage Users"] {
                    background: url(../images/navbar/users.svg) left center / contain no-repeat;
                }
                &[type="Manage Roles"] {
                    background: url(../images/navbar/roles.svg) left center / contain no-repeat;
                }
                &[type="Manage Holidays"] {
                    background: url(../images/navbar/holidays.svg) left center / contain no-repeat;
                }
                &[type="Reports"] {
                    background: url(../images/navbar/reports.svg) left center / contain no-repeat;
                }
                &[type="Manage Agents"] {
                    background: url(../images/navbar/manageAgent-W.png) left center / contain no-repeat;
                }
                &[type="Log Out"] {
                    background: url(../images/navbar/logout.svg) left center / contain no-repeat;
                }
                &[type="Manage Software"] {
                    background: url(../images/navbar/promoter-score.png) left center / contain no-repeat;
                }
                &:hover,
                &.active {
                    background-color: #f5e9e9 !important;
                    color: #0e0000;
                    &[type="Dashboard"] {
                        background: url(../images/navbar/dashboard-h.svg) left center / contain no-repeat;
                    }
                    &[type="Analytics"] {
                        background: url(../images/navbar/analysis-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Projects"] {
                        background: url(../images/navbar/projects-h.svg) left center / contain no-repeat;
                    }
                    &[type="Duedate Calendar"] {
                        background: url(../images/navbar/calendar-h.svg) left center / contain no-repeat;
                    }
                    &[type="Invoices"] {
                        background: url(../images/navbar/invoices-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Storage"] {
                        background: url(../images/navbar/storage-h.svg) left center / contain no-repeat;
                    }
                    &[type="Net Promoter Score"] {
                        background: url(../images/navbar/promotor-code-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Customers"] {
                        background: url(../images/navbar/customers-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Branches"] {
                        background: url(../images/navbar/branches-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Services"] {
                        background: url(../images/navbar/services-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Added Services"] {
                        background: url(../images/navbar/added-services-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Partners"] {
                        background: url(../images/navbar/partners-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Users"] {
                        background: url(../images/navbar/users-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Roles"] {
                        background: url(../images/navbar/roles-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Holidays"] {
                        background: url(../images/navbar/holidays-h.svg) left center / contain no-repeat;
                    }
                    &[type="Reports"] {
                        background: url(../images/navbar/reports-h.svg) left center / contain no-repeat;
                    }
                    &[type="Manage Agents"] {
                        background: url(../images/navbar/manageAgent-h.png) left center / contain no-repeat;
                    }
                    &[type="Manage Software"] {
                        background: url(../images/navbar/promotor-code-h.svg) left center / contain no-repeat;
                    }
                }
                img {
                    height: 18px;
                    margin: 0px 10px 0;
                }
                .nav-icon {
                    flex: 0 !important;
                }
            }
        }
        .nav-title {
            color: #425b50;
            letter-spacing: 0.13px;
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            margin-top: 0;
            padding: 10px 18px;
        }
    }
}
.wrapper {
    .content {
        background: #f0edee;
    }
    .header {
        height: 48px;
        border: none;
        --cui-header-min-height: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin: 0 15px;
        padding: 0px 0;
        .header-toggler {
            svg {
                color: #8b0000;
            }
        }
        .header-nav {
            .input-group {
                .input-group-text {
                    padding-right: 6px;
                }
                input.form-control {
                    padding-left: 0;
                }
            }
        }
        .container-fluid {
            background-color: #fff;
            .header-nav {
                .nav-item {
                    display: flex;
                    align-items: center;
                    height: 30px;
                    .nav-link {
                        padding: 0px 12px;
                        display: flex;
                        align-items: end;
                        justify-content: center;
                        height: 1005;
                        .version {
                            margin-bottom: 0;
                            font-size: 12px;
                            color: #000;
                            text-decoration: none;
                            margin-left: 4px;
                            line-height: 1;
                            text-align: center;
                        }
                        svg {
                            color: #8b0000;
                            width: 1.3rem;
                            height: 1.3rem;
                        }
                        img.comments {
                            height: 18px !important;
                            width: 18px !important;
                        }
                        .badge {
                            position: absolute;
                            top: -2px;
                            margin-left: 17px;
                            width: auto;
                            height: 18px;
                            background: #ff7979 0% 0% no-repeat padding-box;
                            border: 2px solid #f9f2f2;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 8px !important;
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 4px;
                        }
                    }
                    .input-group {
                        .input-group-text {
                            background: #fff;
                            border-radius: 4px 0px 0px 4px;
                            border: 1px solid #d3d3d3;
                            border-right: 0;
                            img {
                                height: 14px !important;
                                width: 14px !important;
                            }
                        }
                        input {
                            background: #fff;
                            border: 1px solid #d3d3d3;
                            border-left: 0;
                            border-radius: 0px 4px 4px 0px;
                            color: #000;
                            font-size: 13px;
                            font-weight: 400;
                            width: 314px;
                            padding: 5.25px;
                            &::placeholder {
                                color: #5e3737;
                            }
                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }
                        }
                        .close-btn {
                            position: absolute;
                            right: 6px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            i {
                                font-size: 12px;
                                color: rgba(136, 0, 0, 0.7215686275);
                                font-weight: normal;
                                cursor: pointer;
                            }
                        }
                    }
                    .avatar {
                        width: 1.8rem;
                        height: 1.8rem;
                    }
                    img {
                        width: 1.8rem;
                        height: 1.8rem;
                    }
                    .profile {
                        display: flex;
                        align-items: center;
                        img {
                            border-radius: 50%;
                        }
                        .user-detail {
                            margin-left: 10px;
                            h2 {
                                letter-spacing: 0.13px;
                                color: #022c1d;
                                opacity: 1;
                                font-size: 13px;
                                font-weight: 600;
                                margin-bottom: 1px;
                                text-transform: math-auto;
                                &::first-letter {
                                    text-transform: uppercase;
                                }
                            }
                            p {
                                letter-spacing: 0.13px;
                                color: #425b50;
                                opacity: 1;
                                font-size: 14px;
                                font-weight: 400;
                                margin-bottom: 0;
                            }
                        }
                    }
                    .profile-dropdown {
                        box-shadow: 0px 3px 6px #0000000f;
                        border-radius: 4px;
                        border: none;
                        left: -28px !important;
                        min-width: 140px;
                        padding-bottom: 0;
                        .dropdown-item {
                            letter-spacing: 0.14px;
                            color: #243c32;
                            font-size: 13px;
                            font-weight: 600;
                            padding: 8px;
                            display: flex;
                            align-items: center;
                            font-family: "Inter", sans-serif;
                            &:hover,
                            &:focus {
                                background-color: #ffdada;
                                color: #8b0000;
                            }
                            svg {
                                width: 0.9rem;
                                height: 0.9rem;
                            }
                        }
                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                    }
                    .notification-dropdown {
                        opacity: 1;
                        border: none;
                        border-radius: 4px;
                        width: 350px;
                        margin-right: -100px !important;
                        top: 18px !important;
                        box-shadow: 0px 3px 6px #45080815;
                        border: 1px solid #4508080a;
                        padding-bottom: 0;
                        transform: translate3d(0px, 20px, 0px) !important;
                        right: 0 !important;
                        left: -438% !important;
                        height: 75vh;
                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0px;
                            right: 122px;
                            width: 15px;
                            height: 15px;
                            background-color: #8b0000;
                            -webkit-transform: translateY(-50%) rotate(45deg);
                            -moz-transform: translateY(-50%) rotate(45deg);
                            -ms-transform: translateY(-50%) rotate(45deg);
                            transform: translateY(-49%) rotate(45deg);
                            z-index: 0;
                        }
                        &.comment {
                            margin-right: -140px !important;
                            left: -386% !important;
                            &::before {
                                right: 160px;
                            }
                        }
                        .dropdown-header {
                            background: #8b0000;
                            border-radius: 8px 8px 0px 0px;
                            letter-spacing: 0.14px;
                            color: #ffffff;
                            opacity: 1;
                            font-size: 16px;
                            font-weight: 500;
                            text-align: left;
                            display: flex;
                            justify-content: space-between;
                            span {
                                float: right;
                                cursor: pointer;
                            }
                            a {
                                padding: 0;
                            }
                            .btn-close {
                                font-size: 12px;
                                filter: invert(1);
                                opacity: 1;
                                fill: #fff;
                                --cui-btn-close-color: #fff;
                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                        }
                        .total-count {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background: #f7f7f7;
                            padding: 10px;
                            margin-bottom: 10px;
                            h2 {
                                letter-spacing: 0px;
                                color: #1c0000;
                                opacity: 1;
                                font-size: 14px;
                                font-weight: 400;
                                margin-bottom: 0;
                            }
                            a {
                                letter-spacing: 0px;
                                color: #8b0000;
                                opacity: 1;
                                font-size: 12px;
                                font-weight: 400;
                                text-decoration: none;
                                cursor: pointer;
                            }
                        }
                        .dropdown-item {
                            &.active,
                            &:active,
                            &:hover,
                            &:focus {
                                background-color: transparent !important;
                            }
                            &:hover {
                                .title {
                                    text-decoration: underline;
                                    color: #8b0000;
                                    cursor: pointer;
                                }
                            }
                        }
                        .note-item {
                            margin: 0;
                            background: #fcfcfc;
                            border-radius: 4px;
                            padding: 10px 10px 30px;
                            &.active {
                                border-left: 4px solid #ff7979;
                            }
                            .note-icon {
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                margin-right: 15px;
                                svg {
                                    color: #ff3d00;
                                }
                            }
                            .description {
                                .title {
                                    letter-spacing: 0px;
                                    color: #120303;
                                    text-transform: capitalize;
                                    opacity: 1;
                                    font-size: 13px;
                                    font-weight: 500;
                                    text-align: left;
                                    margin-bottom: 4px;
                                    white-space: initial;
                                }
                                .designation {
                                    letter-spacing: 0px;
                                    color: #898686;
                                    text-transform: capitalize;
                                    opacity: 1;
                                    font-size: 12px;
                                    font-weight: 400;
                                    text-align: left;
                                    margin-bottom: 8px;
                                    white-space: initial;
                                }
                            }
                            .address {
                                letter-spacing: 0px;
                                color: #120303;
                                text-transform: math-auto;
                                opacity: 1;
                                font-size: 12px;
                                font-weight: 400;
                                margin-bottom: 8px;
                                white-space: initial;
                            }
                            .date {
                                letter-spacing: 0px;
                                color: #7c7c7c;
                                float: right;
                                text-transform: uppercase;
                                opacity: 1;
                                font-size: 10px;
                                font-weight: 500;
                                margin-bottom: 4px;
                                white-space: initial;
                            }
                            .delete-icon {
                                float: right;
                                cursor: pointer;
                                svg {
                                    color: #747c8a;
                                    width: 0.8rem;
                                    height: 0.8rem;
                                }
                                &:hover {
                                    svg {
                                        color: #8b0000;
                                    }
                                }
                            }
                        }
                        .dropdown-divider {
                            margin: 0;
                            border-color: #ebf0e9;
                        }
                        .notify-block {
                            overflow: auto;
                            height: 68vh;
                            background-color: #fff;
                        }
                        .footer {
                            background: #e8cccc;
                            box-shadow: 0px 3px 6px #45080815;
                            border-radius: 0px 0px 8px 8px;
                            padding: 0;
                            border-top: 0;
                            min-height: 36px;
                            a {
                                background: #e8cccc;
                                letter-spacing: 0.13px;
                                color: #0e0000;
                                text-transform: capitalize;
                                font-size: 14px;
                                font-weight: 600;
                                text-align: center;
                                padding: 0;
                                text-decoration: none;
                                width: 100%;
                                &:hover {
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    .nav-link {
                        border-right: 0 !important;
                        padding-right: 0;
                    }
                }
                &.search-bar {
                    position: relative;
                    input {
                        &::placeholder {
                            font-size: 12px !important;
                        }
                    }
                    input[role="combobox"] ~ div {
                        background-color: #fff !important;
                        margin-left: -30px;
                        padding: 0 !important;
                        max-width: 344px;

                        div.project-drop {
                            cursor: pointer;
                            border-bottom: 1px solid #eeeeee;
                            h2 {
                                letter-spacing: 0px;
                                color: #120303;
                                opacity: 1;
                                font-size: 13px;
                                font-weight: 500;
                                text-align: left;
                                margin-bottom: 2px;
                                line-height: 20px;
                                text-transform: capitalize;
                                margin-bottom: 0 !important;
                            }
                            .project-sub {
                                display: flex;
                                justify-content: space-between;
                                h2 {
                                    font-size: 12px !important;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    &.created {
                                        color: #800080;
                                    }

                                    &.overdue {
                                        color: #e93d59;
                                    }

                                    &.inreview,
                                    &.in-review {
                                        color: #a14c00;
                                    }

                                    &.autoaccepted {
                                        color: #3891b9;
                                    }

                                    &.reopened {
                                        color: #bf4ea5;
                                    }

                                    &.active {
                                        color: #57b14f;
                                    }

                                    &.inactive {
                                        color: #f44336;
                                    }

                                    &.accepted {
                                        color: #7fc578;
                                    }

                                    &.underprogress {
                                        color: #66afaf;
                                    }

                                    &.autoaccepted {
                                        color: #2c8bb4;
                                    }
                                }
                                p {
                                    letter-spacing: 0px;
                                    color: #5e3737;
                                    opacity: 1;
                                    font-size: 10px;
                                    font-weight: 500;
                                    margin-bottom: 0;
                                    white-space: nowrap;
                                    margin-right: 8px;
                                }
                                .project-li {
                                    display: flex;
                                    align-items: baseline;
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .sidebar:not(.sidebar-end):not(.show) {
        margin-left: 0;
    }
}
