@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700&display=swap');
@import "@coreui/coreui/scss/coreui";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.Container-fluid {
    min-height: 100vh;
    background-color: #eee;
    &.login{
        background: transparent linear-gradient(180deg, #AA1F2E 0%, #6E0E0E 100%) 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        padding: 0px 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .card {
            height: auto;
            background-color: #fff;
            position: relative;
            box-shadow: 0px 3px 6px #0B01013C;
            border-radius: 1px;
            font-family: 'Inter', sans-serif;
            padding: 0px;
            border: none;
            .card-header{
                background-color: #fff;
                border-color: #E2E2E2;
                text-align: center;
                padding: 18px;
                letter-spacing: 0.78px;
                color: #210205;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
            }
            .left-form{
                width: 50%;
                padding: 25px 80px;
                .captcha-sec{
                    position: relative;
                    #reload_href{
                        position: absolute;
                        right: 0px;
                        top: 0px;
                        background-color: #989898;
                        padding: 7px 14px;
                        border-radius: 0px 4px 4px 0px;
                        i{
                            font-size: 13px;
                            position: relative;
                            top: 0;
                            right: 0;
                        }
                    }
                    div:has(#canv){
                        border: 1px dashed #E2E2E2;
                        border-radius: 4px 0px 0px 4px;
                        padding-left: 2px;
                    }
                }
            }
            .logo-sec {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                margin-bottom: 15px;
                img{
                    height: 30px;
                }
                h2{
                    letter-spacing: 0.78px;
                    color: #210205;
                    font-size: 22px;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 10px;
                }
                p{
                    letter-spacing: 0.54px;
                    color: #382E2F;
                    opacity: 1;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }
            .input-text{
                position: relative;
                margin-top: 10px;
                i {
                    position: absolute;
                    right: 10px;
                    top: 44px;
                    font-size: 18px;
                    cursor: pointer;
                }
                img{
                    &.lock {
                        position: absolute;
                        left: 8px;
                        top: 11px;
                        cursor: pointer;
                        font-size: 15px;
                        height: 20px;
                    }
                    &.user {
                        position: absolute;
                        left: 5px;
                        cursor: pointer;
                        top: 6px;
                        height: 28px;
                    }
                }
                input{
                    background: #F2F1F1;
                    border-radius: 4px;
                    border: none;
                    outline: 0;
                    letter-spacing: 0.14px;
                    color: #222 !important;
                    font-size: 14px;
                    font-weight: 500;
                    opacity: 1;
                    height: 42px;
                    width: 100%;
                    padding: 0 10px;
                    &::placeholder{
                        color: #878080 !important;
                    }
                    &:-internal-autofill-selected{
                        background-color: #E1F8ED !important;
                    }
                    &:focus{
                        box-shadow: none;
                        outline: none;
                    }
                }
                label{
                    letter-spacing: 0px;
                    color: #120303;
                    opacity: 1;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 4px;
                }
                &.otp{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    input{
                        width: 50px;
                        margin: 6px;
                        background-color: #F9F2F2;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
            .buttons{
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    height: 42px;
                    width: 100%;
                    border: none;
                    background: #AA1F2E;
                    border-radius: 4px;
                    opacity: 1;
                    font-size: 16px;
                    font-weight: 500;
                    outline: 0;
                    color: #fff;
                    cursor: pointer;
                    transition: all 0.5s;
                    a{
                        text-decoration: none;
                        color: inherit;
                    }
                    &:hover {
                        background-color: #911825;
                        color: #fff;
                    }
                }
            }
            .forgot {
                margin-top: 10px;
                font-size: 10px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                a{
                    letter-spacing: 0px;
                    color: #6F595B;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 13px;
                    font-weight: 400;
                    text-transform: capitalize;
                    &:hover{
                        color: #BF5762;
                    }
                }
                .form-check{
                    display: flex;
                    align-items: center;
                    margin: 0;
                    label{
                        letter-spacing: 0px;
                        color: #382E2F;
                        font-size: 13px;
                        font-weight: 400;
                    }
                    input{
                        accent-color: #AA1F2E;
                        width: 14px;
                        height: 14px;
                        margin-right: 4px;
                        margin-top: 0;
                        &:checked{
                            background-color: #AA1F2E;
                            border-color: #AA1F2E;
                        }
                        &:focus{
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }
            }
            .old-portal {
                p {
                    letter-spacing: 0px;
                    color: #6F595B;
                    opacity: 1;
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 0;
                }
                a {
                    letter-spacing: 0px;
                    opacity: 1;
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 0;
                    text-underline-offset: 3px;
                }
            }
            .register {
                margin-top: 15px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    color: #022C1D;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.13px;
                    a{
                        letter-spacing: 0.13px;
                        color: #008C43;
                        opacity: 1;
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
            .g-recaptcha{
                margin: 20px 0;
                div{
                    width: 350px !important;
                }
            }
            iframe {
                height: 76px;
                width: 302px;
            }
            div#rc-anchor-container {
                width: 94%;
            }
        }
        .right-img{
            width: 50%;
            // height:490px;
            img{
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
        .back-btn{
            position: absolute;
            left: 20px;
            top: 20px;
            img{
                height: 16px;
            }
            span{
                background: #B96666;
                border-radius: 4px;
                padding: 10px 14px;
                margin-right: 10px;
                svg{
                    color: #FCFFFC;
                }
            }
            a{
                text-decoration: none;
                letter-spacing: 0px;
                color: #FCFFFC;
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
    &.register{
        background: transparent linear-gradient(141deg, #009E4F 0%, #00CC7D 100%) !important;
        .card {
            height: auto;
            width: 650px;
            background-color: #fff;
            position: relative;
            box-shadow: 0px 3px 6px #0000000F;
            border-radius: 8px;
            font-family: 'Inter', sans-serif;
            padding: 32px;
            .logo-sec {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                margin-bottom: 15px;
                .icon{
                    height: 45px;
                }
            }
            .horizontal{
                display: flex;
                align-items: center;
            }
            .input-text{
                position: relative;
                margin-top: 15px;
                i {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    font-size: 18px;
                    cursor: pointer;
                    &:not(.righticon){
                        left: 10px;
                        top: 14px;
                        font-size: 15px;
                        cursor: pointer;
                        color: #009E4F;
                    }
                }
                img{
                    &.lock {
                        position: absolute;
                        left: 8px;
                        top: 11px;
                        cursor: pointer;
                        font-size: 15px;
                        height: 20px;
                    }
                    &.user {
                        position: absolute;
                        left: 5px;
                        cursor: pointer;
                        top: 6px;
                        height: 28px;
                    }
                }
                input{
                    background-color: #E1F8ED;
                    border-radius: 6px;
                    border: none;
                    outline: 0;
                    letter-spacing: 0.14px;
                    color: #222 !important;
                    font-size: 14px;
                    font-weight: 500;
                    opacity: 1;
                    height: 42px;
                    width: 100%;
                    padding: 0 10px;
                    padding-left: 36px;
                    &[type="password"] {
                        padding-right: 27px;
                    }
                    &::placeholder{
                        color: #243C32 !important;
                    }
                    &:-internal-autofill-selected{
                        background-color: #E1F8ED !important;
                    }
                }
            }
            .buttons{
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                button {
                    height: 42px;
                    width: 100%;
                    border: none;
                    background: #009E4F;
                    border-radius: 6px;
                    opacity: 1;
                    font-size: 16px;
                    font-weight: 500;
                    outline: 0;
                    color: #fff;
                    cursor: pointer;
                    transition: all 0.5s;
                    &:hover {
                        background-color: #fff;
                        color: #009E4F;
                        border: 1px solid #009E4F;
                    }
                }
            }
            .forgot {
                margin-top: 15px;
                font-size: 10px;
                display: flex;
                justify-content: end;
                align-items: center;
                a{
                    letter-spacing: 0.13px;
                    color: #022C1D;
                    opacity: 1;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .register {
                margin-top: 15px;
                font-size: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                p{
                    color: #022C1D;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 0.13px;
                    a{
                        letter-spacing: 0.13px;
                        color: #008C43;
                        opacity: 1;
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
            .g-recaptcha{
                margin: 20px 0;
            }
            iframe {
                height: 76px;
                width: 302px;
            }
        }
    }
}

.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #022c1dfa;
    position: absolute;
    width: 100%;
    z-index: 999;
    img{
        height: 40px !important;
        animation: zoom-in-zoom-out 1s ease infinite;
        margin-left: -48px !important;
        }
        span{
        margin-left: -50px;
        margin-top: 15px;
        color: #FDFFFE;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.4px;
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@media (max-width: 992px){
    .Container-fluid.login .card .left-form, .Container-fluid.login .right-img{
        width: 100% !important;
    }
    .Container-fluid.login{
        padding: 15px !important;
    }
    .Container-fluid.login .next{
        padding: 0 !important;
    }
}
@media (max-width: 576px){
    .order-sm-2 {
        order: 2!important;
    }
    .order-sm-1 {
        order: 1 !important;
    }
    .Container-fluid.login .card .forgot .form-check input{
        width: 13px;
        height: 13px;
    }
    .Container-fluid.login .card .forgot .form-check label, .Container-fluid.login .card .forgot a{
        font-size: 13px !important;
    }
    .Container-fluid.login .card .forgot .form-check{
        display: flex;
        align-items: center;
    }
    .Container-fluid.login{
        padding: 15px !important;
    }
    .Container-fluid.login .card .left-form{
        padding: 25px 25px !important;
    }
    .Container-fluid.login .card .input-text.otp input{
        width: 35px !important;
    }
    .Container-fluid.login .next .card-body.px-5{
        padding: 15px !important;
    }
    .Container-fluid.login .next{
        margin-top: 45px;
    }
    .Container-fluid.login .card .card-header{
        font-size: 20px;
    }
}