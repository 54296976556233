@media (min-width: 320px) and (max-width: 576px) {
    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable {
        width: 310px !important;
        border-radius: 0;
    }

    .wrapper.m-bg {
        filter: brightness(0.5);
        pointer-events: none;
    }

    .sm-logo {
        display: block;
    }

    .lg-logo {
        display: none;
    }

    .sidebar .sidebar-brand {
        background: #FFFFFF;
        justify-content: space-between !important;
        padding-left: 20px;
        border-radius: 0;
    }

    .wrapper .header {
        border-radius: 0;
        margin: 0;
    }

    .sidebar .sidebar-brand .close-btn {
        display: block;
        color: #8B0000;
        font-weight: 600;
        background-color: transparent;
        border: 0;
    }

    .sidebar .sidebar-brand .close-btn i {
        font-size: 20px;
    }

    .sidebar .sidebar-brand .close-btn:focus {
        background-color: transparent;
        border: 0 !important;
        box-shadow: none;
        outline: none;
    }

    .sidebar .sidebar-brand:first-child {
        border-bottom: 1.4px solid #D4D4D4;
    }

    .sidebar .sidebar-brand .nav-item {
        list-style: none;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link {
        width: auto !important;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link.active,
    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link:hover {
        border-radius: 4px !important;
    }

    .sidebar .sidebar-brand.side-profile {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .sidebar .sidebar-brand.side-profile .dropdown-menu.show {
        position: unset !important;
        display: contents;
    }

    .sidebar .sidebar-brand.side-profile .dropdown .nav-link::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "\f078";
        font-family: 'FontAwesome';
        color: #8B0000;
        position: absolute;
        right: -13px;
        top: 4px;
    }

    .sidebar .sidebar-brand .profile {
        display: flex;
        align-items: center;

    }

    .sidebar .sidebar-brand .profile img {
        border-radius: 50%;
        width: 1.8rem !important;
        height: 1.8rem !important;
    }

    .sidebar .sidebar-brand .profile .user-detail {
        margin-left: 10px;
    }

    .sidebar .sidebar-brand .profile .user-detail h2 {
        letter-spacing: 0.13px;
        color: #022C1D;
        opacity: 1;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 1px;
    }

    .sidebar .sidebar-brand .profile .user-detail p {
        letter-spacing: 0.13px;
        color: #425B50;
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .sidebar .sidebar-brand .profile-dropdown {
        box-shadow: 0px 3px 6px #0000000F;
        border-radius: 4px;
        border: none;
        left: -14px !important;
        min-width: 140px;
        padding-bottom: 0;
    }

    .sidebar .sidebar-brand .profile-dropdown a {
        text-decoration: none;
    }

    .sidebar .sidebar-brand .profile-dropdown .dropdown-item {
        letter-spacing: 0.14px;
        color: #243C32;
        font-size: 14px;
        font-weight: 600;
        padding: 8px;
        display: flex;
        align-items: center;

    }

    .sidebar .sidebar-brand .profile-dropdown .dropdown-item a {
        text-decoration: none;
        color: inherit;
    }

    .wrapper .header .container-fluid .header-nav.search-bar {
        visibility: hidden;
        width: 50%;
    }

    .sidebar .sidebar-brand .profile-dropdown .dropdown-item:hover,
    .sidebar .sidebar-brand .profile-dropdown .dropdown-item:focus {
        background-color: #ffdada;
        color: #8b0000;
    }

    .wrapper .header .container-fluid .header-nav.profile-nav {
        display: none;
    }

    .Container-fluid.login .card .logo-sec p br {
        display: none;
    }

    .Container-fluid.login .card .logo-sec.mx-5 {
        margin: 0 2rem !important;
    }

    .content .card-list .card {
        display: inline-table;
        border-radius: 10px;
        width: 100%;
    }

    .content .top-dropdown {
        float: right;
    }

    .content .top-dropdown:last-child {
        margin-right: 0 !important;
    }

    .content .top-dropdown.multi-calendar {
        width: auto !important;
    }

    .content .top-dropdown button {
        width: 35px !important;
        height: 35px;
        padding-left: 12px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content .top-dropdown button:has(.Auto-complete-dropdown) {
        overflow: inherit !important;
    }

    .content .dashboard .Auto-complete-dropdown .menu-dropdown {
        right: 0 !important;
        left: unset !important;
        width: 50vw !important;
    }

    .content .top-dropdown button:has(.opted) {
        width: 250px !important;
    }

    .content .top-dropdown button:has(.opted) img {
        position: relative !important;
    }

    .content .top-dropdown:has(.opted) {
        float: left !important;
    }

    .content .top-dropdown button select.opted {
        opacity: 1 !important;
    }

    .content .card-header .top-dropdown button {
        width: 100% !important;
        justify-content: baseline !important;
    }

    .content .card-header .top-dropdown button .form-control {
        opacity: 1 !important;
        padding-left: 0;
    }

    .content .card-header .top-dropdown button img {
        position: relative !important;
    }

    .content .top-dropdown button:not(.delete-btn) {
        color: transparent !important;
    }

    .content .top-dropdown button:not(.delete-btn):focus {
        color: transparent !important;
    }

    .content .Auto-complete-dropdown .arrow {
        display: none;
    }

    .top-dropdown .react-datepicker-wrapper input {
        /* width: 40px !important; */
        height: 35px !important;
        padding-left: 28px !important;
        overflow: hidden;
        /* opacity: 0; */
    }

    .content .top-dropdown .react-datepicker-wrapper {
        border: 1px solid #DCE2E3;
        background-color: #fff;
        border-radius: 4px;
    }

    .content .top-dropdown .react-datepicker-wrapper svg {
        position: absolute;
        left: 9px;
        top: 9px;
        font-size: 16px;
    }

    .top-dropdown .react-datepicker-wrapper .react-datepicker__calendar-icon {
        width: 1.3rem;
        height: 1.3rem;
    }

    .content .top-dropdown button img {
        height: 14px;
        margin-right: 5px;
        position: absolute;
    }

    .content .top-dropdown button select,
    .content .top-dropdown button .form-control {
        position: relative;
        opacity: 0;
    }

    .content .card.card-table .card-body .chart-datepick input {
        font-size: 11px;
        width: 80px;
        padding-right: 0;
        padding-left: 26px;
    }

    .content .card.card-table .card-body .chart-datepick .react-datepicker__calendar-icon {
        padding-left: 0;
        padding-right: 0;
        width: 0.8em;
        height: 0.8em
    }

    .content .card.card-table .card-body .chart-datepick p {
        display: flex;
    }

    .content .card.card-table .card-body .chart-datepick p,
    .content .card.card-table .card-body .chart-datepick p span {
        font-size: 12px !important;
        margin-left: 4px;
    }

    .nps-chart canvas {
        width: 90% !important;
        height: auto !important;
    }

    .content .top-dropdown button i {
        font-size: 18px !important;
        margin-right: 15px
    }

    .content .input-group .close-btn {
        display: none;
    }

    .content .input-group input[type="date"] {
        width: 40px;
        padding-left: 12px;
        border-radius: 8px !important;
        height: 40px;
    }

    .content .input-group input[placeholder="Search"] {
        height: 35px;
        width: 35px;
        border-radius: 4px;
        transition: all .5s ease-in-out;
        padding-right: 28px;
        padding-left: 6px;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .content .input-group input[placeholder="Search"]:focus {
        width: 200px;
        transition: all 500ms ease-in-out;
        padding-left: 30px;
    }

    .content .input-group .input-group-text {
        width: 35px;
        height: 35px;
        cursor: pointer;
        border-radius: 4px;
        position: absolute;
        left: 0px;
        background-color: transparent;
        pointer-events: painted;

    }

    .content .input-group .input-group-text:focus~.input {
        width: 200px;
        transition: all 500ms ease-in-out;
    }

    .content .input-group .input-group-text img {
        height: 14px;
        z-index: 99;
    }

    .content .top-dropdown .delete-btn.btn {
        width: 35px !important;
        padding-left: 12px;
        overflow: hidden;
        height: 35px !important;
        white-space: nowrap;
        display: block !important;
    }

    /* .content .top-dropdown .delete-btn.btn.clearall, .content .top-dropdown.addendum .btn{
        width: auto !important;
    }
    .content .top-dropdown.addendum .btn{
        color: #0E0000 !important;
    } */
    .content .top-dropdown .delete-btn.btn.clearall::before {
        content: none;
    }

    .content .top-dropdown .delete-btn.btn::before {
        content: "\f067";
        font-family: 'FontAwesome';
        font-size: 18px;
        margin-right: 15px;
        position: relative;
        top: -20%;
    }

    .content .top-dropdown .delete-btn.btn.send::before {
        content: "\f1d9";
    }

    .content .top-dropdown .delete-btn.btn.delete::before {
        content: "\f014";
    }

    .view-page.project .card .card-header .v-title h2 {
        white-space: inherit !important;
    }

    .content .card-list .table-area .table tr,
    .content .dashboard .card.card-table .card-body .table-area table td,
    .view-page.project .card-body .table-area table tr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .content .dashboard .card.card-table .card-body .table-area table td.float-end {
        float: unset !important;
    }

    .content .card.card-table.block-t .card-header,
    .content .card.card-table.block-t .card-body .card {
        display: inline-table !important;
    }

    .stepper-progress {
        margin: 0 !important;
    }

    .view-page.project .card-body .table-area table:has(.file-section) tr {
        display: block !important;
    }

    .content .card-list .table-area .table tr td:first-child,
    .content .dashboard .card.card-table .card-body .table-area table td:first-child {
        width: 90%;
        min-width: 90%;
        max-width: 90%;
    }

    .content .card-list .table-area .table tr td,
    .content .dashboard .card.card-table .card-body .table-area table td,
    .view-page.project .card-body .table-area table td {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
    }

    .set-position .button-position {
        position: relative !important;
        bottom: 0 !important;
    }

    .content .dashboard .card.card-table .card-body .card {
        display: block !important;
    }

    .content .view-page .card .toggle-div .v-title {
        margin-left: 0 !important;
    }

    .content .view-page .card .toggle-btn {
        position: relative !important;
        display: block !important;
    }

    .content .view-page .card .toggle-btn p {
        margin: 0.5rem 0;
    }

    .content .view-page .card .pricing-table {
        display: block;
        overflow: auto;
    }

    .content .view-page .card .input-text .form-check label {
        font-size: 11px;
        white-space: nowrap;
    }

    .content .card-list .card-body .table tbody td:has(.action-btn) {
        min-width: 10% !important;
        max-width: 10% !important;
        width: 10% !important;
        float: right !important;
    }

    .content .card-list .card-body .table tbody tr:has(.action-btn) td:not(:first-child) {
        width: 45%;
        min-width: 45%;
        max-width: 45%;
    }

    .content .calendar-list,
    .content .view-page .template .role-list {
        flex-direction: column;
    }

    .content .calendar-list .card,
    .content .view-page .template .role-list .card {
        width: 100%;
    }

    .content .calendar-list .card .card-header .v-title h2 img {
        height: 16px;
    }

    .content .tab-pane[aria-labelledby=week-tab-pane] .calendar-list .cal-box .card .card-header {
        border-radius: 8px !important;
    }

    .dashboard .pe-0,
    .analytics .pe-0 {
        padding-right: 0.75rem !important;
    }

    .dashboard .ps-2 {
        padding-left: 0.75rem !important;
    }

    .content .view-page .card {
        margin-bottom: 0.5rem !important;
    }

    .dashboard .ps-0,
    .analytics .ps-0 {
        padding-left: calc(var(--cui-gutter-x) * 0.5) !important;
    }

    .delete-modal .modal-dialog {
        width: auto !important;
    }

    .not-found {
        padding: 30px;
    }

    .content .card-list .container,
    .content .card-list .container.md,
    .content .card-list .container.lg,
    .content .card-list .container.sm {
        max-width: 100% !important;
        padding: 0 !important;
    }

    .content .top-dropdown.cal-select .chart-datepick svg {
        left: 2px !important;
        top: 0px !important;
    }

    .content .top-dropdown.cal-select .react-datepicker-wrapper input {
        padding-left: 34px !important;
    }

    .canvasjs-chart-canvas {
        width: 250px !important;
        height: 180px !important;
    }

    .content .card.card-table .analytics-chart .data-labels {
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }

    .content .card.card-table .analytics-chart .data-labels .label {
        margin-bottom: 10px;
    }

    .left-align {
        justify-content: left !important;
        gap: 0 0.5rem;
    }

    .content .breadcrumb li,
    .sub-breadcrumb {
        white-space: nowrap;
    }

    .content .breadcrumb li {
        font-size: 15px;
    }

    .w-custom {
        width: 100% !important;
        min-width: 100% !important;
    }

    .sidebar .sidebar-brand .version {
        color: #000;
    }

    .left-show:has(input[placeholder="Search"]:focus) {
        display: flex;
        align-items: flex-start;
    }

    .left-show .top-dropdown:has(input[placeholder="Search"]:focus) {
        width: 100% !important;
    }

    .d-flex.justify-content-end.left-show:has(input[placeholder="Search"]:focus) {
        justify-content: flex-start !important;
    }

    .d-flex.justify-content-end.left-show .multi-calendar {
        margin-left: 0.5rem !important;
    }

    .content .view-page .card .pricing-table tbody tr td {
        width: 100% !important;
    }

    div.me-4:empty {
        display: none;
    }

    .view-page.project .card .image-input {
        width: 100% !important;
    }

    .view-page.project .card .file-section {
        display: block !important;
    }

    .view-page.project .card .file-section .file-bottom-btn {
        justify-content: flex-start !important;
    }

    .view-page.project .card .uploaded-file {
        width: 100% !important;
    }

    .calendar .nav-pills.top-tab {
        justify-content: left !important;
        float: left !important;
        padding-left: 1rem;
    }

    .calendar .date-pick {
        margin-bottom: 1rem;
    }

    .calendar .month-year {
        justify-content: left !important;
        align-items: flex-start;
        white-space: nowrap;
    }

    .calendar .date-pick input {
        width: 100% !important;
        padding-left: 1px;
        padding-right: 0;
    }

    .content .tab-pane .calendar-list .cal-box .card .card-body {
        width: 225px;
    }

    .content .view-page .template .card .card-body table tr td .company {
        display: block !important;
    }

    .content .view-page .card .add-btn {
        width: 100px !important;
    }
}

@media (min-width: 577px) and (max-width: 767px) {
    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable {
        width: 310px !important;
        border-radius: 0;
    }

    .sidebar .sidebar-brand .version {
        color: #000;
    }

    .wrapper.m-bg {
        filter: brightness(0.5);
        pointer-events: none;
    }

    .sm-logo {
        display: block;
    }

    .lg-logo {
        display: none;
    }

    .sidebar .sidebar-brand {
        background: #FFFFFF;
        justify-content: space-between !important;
        padding-left: 20px;
        border-radius: 0;
    }

    .wrapper .header {
        border-radius: 0;
        margin: 0;
    }

    .sidebar .sidebar-brand .close-btn {
        display: block;
        color: #8B0000;
        font-weight: 600;
        background-color: transparent;
        border: 0;
    }

    .sidebar .sidebar-brand .close-btn i {
        font-size: 20px;
    }

    .sidebar .sidebar-brand .close-btn:focus {
        background-color: transparent;
        border: 0 !important;
        box-shadow: none;
        outline: none;
    }

    .sidebar .sidebar-brand:first-child {
        border-bottom: 1.4px solid #D4D4D4;
    }

    .sidebar .sidebar-brand .nav-item {
        list-style: none;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link {
        width: auto !important;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link.active,
    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable .sidebar-nav .nav-link:hover {
        border-radius: 4px !important;
    }

    .sidebar .sidebar-brand.side-profile {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .sidebar .sidebar-brand.side-profile .dropdown-menu.show {
        position: unset !important;
        display: contents;
    }

    .sidebar .sidebar-brand.side-profile .dropdown .nav-link::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "\f078";
        font-family: 'FontAwesome';
        color: #8B0000;
        position: absolute;
        right: -13px;
        top: 4px;
    }

    .sidebar .sidebar-brand .profile {
        display: flex;
        align-items: center;

    }

    .sidebar .sidebar-brand .profile img {
        border-radius: 50%;
        width: 1.8rem !important;
        height: 1.8rem !important;
    }

    .sidebar .sidebar-brand .profile .user-detail {
        margin-left: 10px;
    }

    .sidebar .sidebar-brand .profile .user-detail h2 {
        letter-spacing: 0.13px;
        color: #022C1D;
        opacity: 1;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 1px;
    }

    .sidebar .sidebar-brand .profile .user-detail p {
        letter-spacing: 0.13px;
        color: #425B50;
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .sidebar .sidebar-brand .profile-dropdown {
        box-shadow: 0px 3px 6px #0000000F;
        border-radius: 4px;
        border: none;
        left: -14px !important;
        min-width: 140px;
        padding-bottom: 0;
    }

    .sidebar .sidebar-brand .profile-dropdown a {
        text-decoration: none;
    }

    .sidebar .sidebar-brand .profile-dropdown .dropdown-item {
        letter-spacing: 0.14px;
        color: #243C32;
        font-size: 14px;
        font-weight: 600;
        padding: 8px;
        display: flex;
        align-items: center;

    }

    .sidebar .sidebar-brand .profile-dropdown .dropdown-item a {
        text-decoration: none;
        color: inherit;
    }

    .wrapper .header .container-fluid .header-nav.search-bar {
        visibility: hidden;
        width: 50%;
    }

    .sidebar .sidebar-brand .profile-dropdown .dropdown-item:hover,
    .sidebar .sidebar-brand .profile-dropdown .dropdown-item:focus {
        background-color: #ffdada;
        color: #8b0000;
    }

    .wrapper .header .container-fluid .header-nav.profile-nav {
        display: none;
    }

    /* .sm-logo {
        display: none;
    } */

    /* .sidebar .sidebar-brand .close-btn {
        display: none;
    } */
    /* .sidebar .sidebar-brand.side-profile{
        display: none;
    } */
    .dashboard .pe-0,
    .analytics .pe-0 {
        padding-right: 15px !important;
    }

    .dashboard .ps-0 {
        padding-left: calc(var(--cui-gutter-x) * 0.5) !important;
    }

    .wrapper .header .container-fluid .header-nav.search-bar {
        visibility: hidden;
        width: 50%;
    }

    .nps-chart canvas {
        height: auto !important;
        width: 90% !important;
    }

    .content .view-page .card .add-btn {
        width: 100px !important;
    }

    .content .card-list .table-area .table tr,
    .content .card-list .card .card-body table tr,
    .view-page.project .card-body .table-area table tr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .content .card-list .table-area .table tr td,
    .content .card-list .card .card-body table tr td,
    .view-page.project .card-body .table-area table td {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
    }

    .content .card-list .table-area.storage .table tr td:first-child {
        width: 80% !important;
        min-width: 80% !important;
        max-width: 80% !important;
    }

    .content .card-list .table-area.storage .table tr td:last-child {
        width: 20% !important;
        min-width: 20% !important;
        max-width: 20% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .dashboard .pe-0 {
        padding-right: 0.75rem !important;
    }

    .dashboard .ps-0 {
        padding-left: 0 !important;
    }

    .dashboard .ps-2 {
        padding-left: 0.75rem !important;
    }

    .nps-chart canvas {
        height: auto !important;
        width: 90% !important;
    }

    .content .card-list .table-area .table tr,
    .content .dashboard .card.card-table .card-body .table-area table td,
    .view-page.project .card-body .table-area table tr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .content .card-list .table-area .table tr td,
    .content .dashboard .card.card-table .card-body .table-area table td,
    .view-page.project .card-body .table-area table td {
        width: 50%;
        min-width: 50%;
        max-width: 50%;
    }
}

@media (max-width: 767px) {
    .wrapper .header .container-fluid .header-nav .nav-item .notification-dropdown {
        transform: translate3d(-87px, 20px, 0px) !important;
    }

    .wrapper .header .container-fluid .header-nav .nav-item .notification-dropdown::before {
        right: 72px !important;
    }

    .responsive-sidebar {
        display: block !important;
        margin: 8px !important;
    }

    .wrapper .header .container-fluid .header-nav.logo-nav img {
        width: auto !important;
    }

    .wrapper .header .header-toggler {
        background-color: #8B0000;
        border-radius: 2px;
        padding-left: 0.75rem !important;
    }

    .wrapper .header .header-toggler svg {
        color: #FFFFFF;
    }

    .wrapper .header .container-fluid .header-nav.logo-nav {
        position: absolute;
        left: 80px;
    }

    .wrapper .header .container-fluid .header-nav.search-bar {
        right: unset !important;
    }

    .filter-modal .modal-dialog {
        right: 12px;
        left: unset;
        min-width: 300px !important;
    }

    .view-page .container.template.small {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .content .view-page .card .form-check {
        margin-bottom: 15px;
    }

    .view-page .container.template.small.profile .row.px-5.mx-5 {
        padding: 0 !important;
        margin: 0 !important;
    }

    .view-page .container.template.small.profile .pe-1 {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .view-page .container.template.small.profile .ps-1 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .content .view-page .template.small .card .reset-btn {
        width: 47% !important;
        height: auto;
        margin-bottom: 0.5rem;
    }

    .content .view-page .template.small.profile .card .reset-btn {
        width: 120px !important;
    }

    .content .date-pick input {
        width: 85px;
        font-size: 11px;
    }

    .content .date-pick a {
        padding: 0px 5px;
    }

    .content .date-pick a i {
        font-size: 10px;
    }

    .content .nav-pills.top-tab {
        float: right;
    }

    .content .nav-pills.top-tab .nav-item {
        width: auto !important;
    }

    .content .nav-pills.top-tab .nav-item .nav-link {
        width: 62px;
        padding: 5px 14px !important;
        font-size: 12px;
    }

    .set-position {
        position: relative;
        top: 0 !important;
    }

    .feedback-modal .modal-dialog {
        width: auto !important;
    }

    .delete-modal.holiday .modal-dialog .modal-body,
    .delete-modal.holiday .modal-dialog .modal-header {
        padding-left: 15px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .view-page .container.template.small.profile .row.px-5.mx-5 {
        padding: 0 !important;
        margin: 0 !important;
    }

    .view-page .container.template.small.profile .pe-1 {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .view-page .container.template.small.profile .ps-1 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .wrapper .header .container-fluid .header-nav.search-bar {
        right: unset !important;
    }

    .wrapper .header .container-fluid .header-nav .nav-item .input-group input {
        width: auto !important;
    }

    .content .view-page .template.small .card-body .rate-experience .rate-selection button {
        padding: 2px 8px;
        font-size: 12px;
        margin: 2px;
    }

    .nps-chart canvas {
        width: 90% !important;
    }

}

@media (min-width: 768px) {
    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable.hide.d-md-flex {
        margin-left: 0 !important;
        width: 4rem !important;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable.hide .sidebar-nav .nav-item {
        margin: 0 !important;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable.hide:not(.sidebar-end)~* {
        --cui-sidebar-occupy-start: 4rem !important;
    }

    .sidebar.sidebar-fixed.sidebar-narrow-unfoldable.hide .sidebar-nav .nav-link {
        padding: 5.8px;
        padding-left: 60px !important;
        background-position: 22px !important;
        background-size: 16px !important;
        width: 12px;
        overflow: hidden;
        padding-right: 0px;
    }

    .sidebar.sidebar-fixed:not(.sidebar-narrow-unfoldable).hide .sidebar-nav .nav-item {
        margin: 3.34px !important;
    }

    .sidebar.sidebar-fixed:not(.sidebar-narrow-unfoldable).hide .sidebar-nav .nav-link {
        padding: 2.5px !important;
        padding-left: 40px !important;
        background-position: 18px !important;
        background-size: 13px !important;
        width: auto !important;
        overflow: unset !important;
        padding-right: 2.5px;
    }

    .sidebar.sidebar-fixed:not(.sidebar-narrow-unfoldable).hide:not(.sidebar-end)~* {
        --cui-sidebar-occupy-start: 13rem !important;
    }

    .sidebar.sidebar-fixed:not(.sidebar-narrow-unfoldable).hide.d-md-flex {
        margin-left: 0 !important;
        width: 13rem !important;
    }

    .sm-logo {
        display: none;
    }

    .lg-logo {
        display: block;
    }

    .sidebar .sidebar-brand .close-btn {
        display: none;
    }

    .sidebar .sidebar-brand.side-profile {
        display: none;
    }

    .wrapper .header .container-fluid .header-nav.logo-nav {
        display: none;
    }

    .content .nav-pills.top-tab .nav-item {
        width: auto !important;
    }

    /* .content .nav-pills.top-tab .nav-item:first-child{
        margin-right: 2px 
    } */
}

@media (min-width: 1400px) {
    .w-max {
        width: 102px;
    }

    .view-page .container {
        max-width: 80% !important;
    }

}

@media (min-width: 1200px) and (max-width: 2000px) {
    .content .Auto-complete-dropdown .menu-dropdown.w-fixed {
        width: 210px !important;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .content .card.card-table .card-body .semi-circle-chart .chart-content {
        top: 6px;
    }

    .content .card.card-table .card-body .semi-circle-chart .percent {
        font-size: 14px;
    }

    .content .card.card-table .card-body .semi-circle-chart .data-title {
        font-size: 8px;
        line-height: 12px;
    }

    .content .card.widget .text {
        font-size: 12px;
    }

    .content .card.widget .showicon img {
        height: 18px;
    }

    .content .card.widget .count {
        font-size: 18px;
    }
}

@media (min-width: 1800px) {
    .w-90 {
        width: 90%;
    }
}